import * as StudyModule from '../../../Modules/StudyModule';
import { Study } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import { translatedMessage } from 'Shared/utils';
const init = () => ({
  type: Study.EDIT_STUDY_FORM_INIT,
});

const success = (files) => ({
  type: Study.EDIT_STUDY_FORM_SUCCESS,
  payload: {
    data: files,
  },
});

const failure = () => ({
  type: Study.EDIT_STUDY_FORM_FAILURE,
});

const editStudyForm = (data) => async (dispatch) => {
  dispatch(init());
  const id = toast.loading(translatedMessage('toast.pending_edit_study'));
  try {
    const response = await StudyModule.Connector.editStudyForm(data);
    dispatch(success(response.data));
    toast.update(id, {
      render: translatedMessage('toast.edit_study'),
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    return response.data;
  } catch (err: any) {
    dispatch(failure());
    toast.update(id, {
      render: translatedMessage('toast.edit_study_error'),
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
    });
    return false;
  }
};

export default editStudyForm;
