import { Modal, Button, Input } from 'Shared/components';
import { Field, Formik } from 'formik';
import { ROUTES } from 'Shared/enums';
import * as Yup from 'yup';
import { useNavigate, generatePath } from 'react-router-dom';
import { useIntl } from 'react-intl';

type AddAdministratorModalType = {
  isModalOpen: boolean;
  closeModal: () => void;
  getUsers: (data) => void;
  addUser: (data) => any;
};

const AddAdministratorModal = ({ isModalOpen, closeModal, addUser, getUsers }: AddAdministratorModalType) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(formatMessage({ id: 'validation.required' })).email(formatMessage({ id: 'validation.email' })),
    lastName: Yup.string()
      .required(formatMessage({ id: 'validation.required' }))
      .min(3, formatMessage({ id: 'validation.min_3' }))
      .max(30, formatMessage({ id: 'validation.max_length_30' }))
      .matches(/^[^\s].*[^\s]$/, formatMessage({ id: 'validation.cannot_start_and_end_with_space' })),
    firstName: Yup.string()
      .required(formatMessage({ id: 'validation.required' }))
      .min(3, formatMessage({ id: 'validation.min_3' }))
      .max(30, formatMessage({ id: 'validation.max_length_30' }))
      .matches(/^[^\s].*[^\s]$/, formatMessage({ id: 'validation.cannot_start_and_end_with_space' })),
  });

  const handleAddAdministrator = async (values, { resetForm }) => {
    const response = await addUser(values);
    if (response) {
      resetForm();
      navigate({ pathname: generatePath(ROUTES.USERS, { page: '1' }) });
      await getUsers({ page: 1 });
      closeModal();
    }
  };

  return (
    <Modal className="add-administrator-modal" isOpen={isModalOpen} onClose={() => closeModal()}>
      <div className="modal-heading">{formatMessage({ id: 'adding_admin' })}</div>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ firstName: '', lastName: '', email: '' }}
        validateOnBlur={false}
        enableReinitialize
        onSubmit={async (values, props) => handleAddAdministrator(values, props)}>
        {({ handleSubmit, errors, resetForm }) => (
          <form className="add-administrator-modal__form" onSubmit={handleSubmit}>
            <Field maxLength={30} label={formatMessage({ id: 'first_name' })} name="firstName" placeholder="" required component={Input} errors={errors} />
            <Field maxLength={30} label={formatMessage({ id: 'last_name' })} name="lastName" placeholder="" required component={Input} errors={errors} />
            <Field label={formatMessage({ id: 'email' })} name="email" placeholder="" required component={Input} treshold={0} maxLength={30} errors={errors} />
            <div className="add-administrator-modal__buttons">
              <Button
                label={formatMessage({ id: 'cancel' })}
                stroke
                onClick={() => {
                  closeModal();
                  resetForm();
                }}
              />
              <Button label={formatMessage({ id: 'save' })} onClick={() => handleSubmit()} />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddAdministratorModal;
