import { useEffect, useRef, useState } from 'react';
import { TopBar, Button, Textarea, Slider } from 'Shared/components';
import { ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { VALIDATION_MESSAGES } from 'Shared/enums';
import { useIntl } from 'react-intl';
import { useTranslation } from 'Shared/utils';

type FirstPageType = {
  content: any;
  nextScreen: any;
  translations: any;
};

const contentType = {
  agreement: 'agreement',
  text: 'text',
  file: 'file',
  question: 'question',
};

const FirstPage = ({ content, nextScreen, translations }: FirstPageType) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [isValidationAgreement, setIsValidationAgreement] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState({});
  const contentStep = [];
  const language = sessionStorage.getItem('language');
  const isEnLanguage = language === 'EN';
  const formRef = useRef(null);
  const initialStorage = JSON.parse(sessionStorage.getItem('studyStep'));
  const studyStorage = initialStorage;
  const [step, setStep] = useState(0);
  const { formatMessage } = useIntl();
  content.map((item, index) => {
    contentStep.push(index);
  });

  useEffect(() => {
    const initialValuesUpdated = {};
    const validationSchemaUpdated = {};

    content[step].content.forEach((item) => {
      if (!!item.questionType) {
        initialValuesUpdated[item.uuid] = studyStorage.firstPage[step].content.find((contentItem) => contentItem.uuid === item.uuid).value || '';
        validationSchemaUpdated[item.uuid] = Yup.string().required(isEnLanguage ? VALIDATION_MESSAGES.REQUIRED_EN : VALIDATION_MESSAGES.REQUIRED);
      }
    });

    setInitialValues(initialValuesUpdated);
    setValidationSchema(validationSchemaUpdated);
  }, [content, step]);

  const handleIncrementStep = async () => {
    const isAgreementStep = content[step].content.some((contentItem) => contentItem.type === contentType.agreement);

    if (isAgreementStep) {
      const agreementCheckboxes = content[step].content.filter((contentItem) => contentItem.type === contentType.agreement);
      const allAgreementsSelected = agreementCheckboxes.every((checkbox) => selectedCheckboxes.includes(checkbox.uuid));

      if (!allAgreementsSelected) {
        setIsValidationAgreement(true);
        return;
      } else {
        setIsValidationAgreement(false);
      }
    }

    const { current: form } = formRef;

    console.log(form);
    await form.handleSubmit();
    const isValid = await form.isValid;
    if (!isValid) {
      return;
    }

    if (contentStep.length - 1 === step) {
      nextScreen();
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 100);
    } else {
      setStep(step + 1);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 100);
    }
  };

  const handleCheckboxChange = (uuid) => {
    if (selectedCheckboxes.includes(uuid)) {
      setSelectedCheckboxes(selectedCheckboxes.filter((item) => item !== uuid));
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, uuid]);
    }
  };
  const translate = useTranslation(formatMessage, translations);

  return (
    <div className="page-wrapper">
      <TopBar formatMessage={formatMessage} translations={translations} className="top-bar--first-page" />
      <div className="first-page">
        <div className="first-page__content">
          <div className="first-page__content-screen">
            <Formik
              validationSchema={Yup.object(validationSchema)}
              initialValues={initialValues}
              validateOnBlur={false}
              enableReinitialize
              innerRef={formRef}
              validateOnMount
              onSubmit={async (values) => {
                const entities = Object.entries(values).map(([key, value]) => {
                  return {
                    questionUuid: key,
                    value,
                  };
                });
                const newFirstPage = studyStorage.firstPage;
                entities.forEach((entity) => {
                  newFirstPage.forEach((screen) => {
                    screen.content.forEach((contentItem) => {
                      if (contentItem.uuid === entity.questionUuid) {
                        contentItem.value = entity.value;
                      }
                    });
                  });
                });
                sessionStorage.setItem('studyStep', JSON.stringify({ ...studyStorage, firstPage: newFirstPage }));
                const firstPageQuestions = [];
                newFirstPage.forEach((screen) =>
                  screen.content.map((item) => {
                    if (!item.questionType) {
                      return;
                    }
                    if (item.questionType === 'open') {
                      firstPageQuestions.push({ uuid: item.uuid, answer: item.value, type: item.questionType });
                    } else {
                      firstPageQuestions.push({ uuid: item.uuid, scaleValue: item.value, type: item.questionType });
                    }
                  }),
                );
                sessionStorage.setItem('firstPageQuestions', JSON.stringify(firstPageQuestions));
              }}>
              {({ handleSubmit, errors, setFieldValue }) => {
                let questionNumber = 0;
                return (
                  <form onSubmit={handleSubmit}>
                    {content[step].content.map((contentItem) => {
                      const htmlContent = contentItem.text;
                      if (htmlContent) {
                        const contentState = htmlContent && ContentState.createFromBlockArray(convertFromHTML(htmlContent));
                        const rawContent = convertToRaw(contentState);
                        if (contentItem.type === contentType.agreement) {
                          const isCheckboxSelected = selectedCheckboxes.includes(contentItem.uuid);
                          const isValidate = !isCheckboxSelected && isValidationAgreement;
                          return (
                            <div className="first-page-checkbox" key={contentItem.uuid}>
                              <label className="first-page-checkbox__option">
                                <input
                                  className="first-page-checkbox__input"
                                  type="checkbox"
                                  checked={isCheckboxSelected}
                                  onChange={() => handleCheckboxChange(contentItem.uuid)}
                                />
                                <div
                                  className="first-page-checkbox__title-wrapper"
                                  key={contentItem.uuid}
                                  dangerouslySetInnerHTML={{ __html: draftToHtml(rawContent) }}
                                />
                              </label>
                              {isValidate && <div className="input__error"> {isEnLanguage ? 'Consent required' : 'Wymagana zgoda'} </div>}
                            </div>
                          );
                        } else if (!!contentItem.questionType) {
                          questionNumber++;
                          if (contentItem.questionType === 'open') {
                            return (
                              <Field
                                key={contentItem.uuid}
                                label={`${questionNumber}. ${contentItem.text}`}
                                name={contentItem.uuid}
                                placeholder=""
                                component={Textarea}
                                errors={errors}
                                required
                              />
                            );
                          } else if (contentItem.questionType === 'scale') {
                            const marks = {};
                            for (let i = 0; i < contentItem.titles.length; i++) {
                              const isLeft = i === 0;
                              const isRight = i === contentItem.titles.length - 1;
                              if (contentItem.titles[i] !== '') {
                                marks[i + 1] = {
                                  label: (
                                    <div className={`slider__marks ${isLeft ? 'slider__marks--left' : ''} ${isRight ? 'slider__marks--right' : ''}`}>
                                      <span>{i + 1}</span>
                                      <span>{contentItem.titles[i]}</span>
                                    </div>
                                  ),
                                };
                              } else {
                                marks[i + 1] = (i + 1).toString();
                              }
                            }

                            return (
                              <div key={contentItem.key}>
                                <Field
                                  key={contentItem.key}
                                  label={`${questionNumber}. ${contentItem.text}`}
                                  name={contentItem.uuid}
                                  component={Slider}
                                  errors={errors}
                                  scaleLevel={parseInt(contentItem.scaleLevel)}
                                  onChange={(e) => setFieldValue(contentItem.uuid, e)}
                                  marks={marks}
                                  required
                                />
                              </div>
                            );
                          }
                        } else if (contentItem.type === contentType.file) {
                          return (
                            <div className="first-page__image" key={contentItem.uuid}>
                              <img src={contentItem.src} alt="" />
                            </div>
                          );
                        } else {
                          return (
                            <div className="first-page__text" key={contentItem.uuid} dangerouslySetInnerHTML={{ __html: draftToHtml(rawContent) }} />
                          );
                        }
                      }
                    })}{' '}
                  </form>
                );
              }}
            </Formik>
          </div>

          <div className="first-page__buttons">
            {step >= 1 && <Button label={translate('back')} stroke onClick={() => setStep(step - 1)} />}
            <Button
              className="first-page__buttons--right"
              label={translate('next')}
              type="submit"
              onClick={() => handleIncrementStep()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstPage;
