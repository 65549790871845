import { useState, useEffect } from 'react';
import { LoginContent, Button } from 'Shared/components';
import { ROUTES } from 'Shared/enums';
import { useNavigate } from 'react-router';
import { MainType } from './Container';
import { useIntl } from 'react-intl';

localStorage.getItem('resendMail');

const ActivateEmailPage = (props: MainType) => {
  const { actions } = props;
  const { checkToken, activateAccount } = actions;
  const [isTokenActive, setIsTokenActive] = useState(false);
  const navigate = useNavigate();
  const urlString = window.location.href;
  const url = new URL(urlString);
  const token = url.searchParams.get('token');
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (token) {
      checkToken(token)
        .then((response) => {
          activateAccount(token);
          response && setIsTokenActive(true);
        })
        .catch((error) => {
          error;
        });
    }
  }, [token]);

  if (!token) {
    return (
      <LoginContent heading={formatMessage({ id: 'confirm_email' })}>
        <div className="suspend-user-modal__descript suspend-user-modal__descript--center">
          {formatMessage({ id: 'confirm_email_description1' })} <b>{formatMessage({ id: 'confirm_email_description2' })}</b>{' '}
          {formatMessage({ id: 'confirm_email_description3' })}
        </div>
      </LoginContent>
    );
  }

  return (
    <>
      {isTokenActive ? (
        <>
          <LoginContent
            heading={formatMessage({ id: 'account_activated' })}
            activate
            descript={formatMessage({ id: 'account_activated_description' })}>
            <div className="login-content__button login-content__button--activate">
              <Button label={formatMessage({ id: 'login' })} type="submit" onClick={() => navigate({ pathname: ROUTES.LOGIN })} />
            </div>
          </LoginContent>
        </>
      ) : (
        <LoginContent
          acitaveLinkExpired
          heading={formatMessage({ id: 'something_went_wrong' })}
          descript={formatMessage({ id: 'link_expired_contact_admin' })}></LoginContent>
      )}
    </>
  );
};

export default ActivateEmailPage;
