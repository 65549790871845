import { connect } from 'react-redux';
import LoginPage from './Main';
import { bindActionCreators } from 'redux';
import { Actions as AuthActions } from 'Modules/AuthModule';
import { getAuthorizedUser } from 'Store/Actions/Auth'


type ConnectedP = {
  changelogs: Array<any>;
};

type DispatchedP = {
  actions: {
    login: (email: string, password: string) => any;
    getAuthorizedUser: () => any;
  };
};
const mapStateToProps = (state) => ({
  changelogs: state.changelogs,
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      login: AuthActions.login,
      getAuthorizedUser: getAuthorizedUser,
    },
    dispatch,
  ),
});
export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
