import { connect } from 'react-redux';
import AddStudyPage from './Main';
import { bindActionCreators } from 'redux';
import { checkToken, recoverPassword, sendPasswordResetLink } from 'Store/Actions/Auth';
import { addStudy, getNewStudyUuid, addStudyFiles, getStudyFiles, deleteStudyFile, getStudyForEdit, editStudyForm } from 'Store/Actions/Study';
import { getStudyByLink as getStudyByLinkSelector, getStudyFiles as getStudyFilesSelector, getStudyForEdit as getStudyForEditSelector } from 'Store/Selectors/StudySelector';

type ConnectedP = {
  studyFiles: any;
  editStudy: any;
  studyByLink: any;
};

type DispatchedP = {
  actions: {
    checkToken: (token) => any;
    recoverPassword: (password, token) => any;
    sendPasswordResetLink: (email) => any;
    addStudy: (data) => any;
    getNewStudyUuid: () => any;
    addStudyFiles:(file, data) => any;
    getStudyFiles:(uuid) => any;
    deleteStudyFile:(uuid) => any;
    getStudyForEdit:(uuid) => any;
    editStudyForm:(data) => any;
  };
};
const mapStateToProps = (state) => ({
  studyFiles: getStudyFilesSelector(state),
  editStudy: getStudyForEditSelector(state),
  studyByLink: getStudyByLinkSelector(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      checkToken: checkToken,
      sendPasswordResetLink: sendPasswordResetLink,
      recoverPassword: recoverPassword,
      addStudy: addStudy,
      getNewStudyUuid: getNewStudyUuid,
      addStudyFiles: addStudyFiles,
      getStudyFiles: getStudyFiles,
      deleteStudyFile: deleteStudyFile,
      getStudyForEdit: getStudyForEdit,
      editStudyForm: editStudyForm,
    },
    dispatch,
  ),
});
export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(AddStudyPage);
