import { useCallback } from 'react';
import { useStore } from 'reactflow';
type FloatingEdgeType = {
  id?: any;
  source?: any;
  target?: any;
  markerEnd?: any;
  style?: any;
  edges?: any;
};
function FloatingEdge({ id, edges, source, target, markerEnd, style }: FloatingEdgeType) {
  const sourceNode: any = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
  const targetNode: any = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

  if (!sourceNode || !targetNode) {
    return null;
  }

  const isSecondLine =
    edges.filter((edge) => (edge.source === target && edge.target === source) || (edge.source === source && edge.target === target)).length > 1;

  const offset = 10;

  const sourceCenterX: number = sourceNode.position.x + sourceNode.width / 2;
  const sourceCenterY: number = sourceNode.position.y + sourceNode.height / 2;

  const targetCenterX: number = targetNode.position.x + targetNode.width / 2;
  const targetCenterY: number = targetNode.position.y + targetNode.height / 2;

  const dx: number = targetCenterX - sourceCenterX;
  const dy: number = targetCenterY - sourceCenterY;
  const distance: number = Math.sqrt(dx * dx + dy * dy);

  let sourceEdgeX: number = sourceNode.position.x + sourceNode.width / 2 + (dx / distance) * (sourceNode.width / 2);
  let sourceEdgeY: number = sourceNode.position.y + sourceNode.height / 2 + (dy / distance) * (sourceNode.height / 2);

  let targetEdgeX: number = targetNode.position.x + targetNode.width / 2 - (dx / distance) * (targetNode.width / 2);
  let targetEdgeY: number = targetNode.position.y + targetNode.height / 2 - (dy / distance) * (targetNode.height / 2);

  if (isSecondLine) {
    const perpendicularX = dy / distance;
    const perpendicularY = -dx / distance;

    sourceEdgeX += perpendicularX * offset;
    sourceEdgeY += perpendicularY * offset;
    targetEdgeX += perpendicularX * offset;
    targetEdgeY += perpendicularY * offset;
  }

  const path: string = `M${sourceEdgeX},${sourceEdgeY} L${targetEdgeX},${targetEdgeY}`;

  const strokeWidth: number = 14;
  const invisibleLineStyle: any = {
    fill: 'none',
    stroke: 'transparent',
    strokeWidth: strokeWidth,
    translate: style.translate,
  };

  return (
    <g>
      <path d={path} style={invisibleLineStyle} />
      <path id={id} className="react-flow__edge-path" d={path} markerEnd={markerEnd} style={style} />
    </g>
  );
}

export default FloatingEdge;

// const isSecondLine =
// edges.filter((edge) => (edge.source === target && edge.target === source) || (edge.source === source && edge.target === target)).length > 1;

// const sourceX: number = sourceNode.position.x + sourceNode.width / 2;
// const sourceY: number = sourceNode.position.y + sourceNode.height / 2;

// const dx: number = targetNode.position.x - sourceNode.position.x;
// const dy: number = targetNode.position.y - sourceNode.position.y;
// const distance: number = Math.sqrt(dx * dx + dy * dy);

// const normalX = -dy / distance; // Wektor normalny w osi X
// const normalY = dx / distance; // Wektor normalny w osi Y

// const offset = isSecondLine ? 10 : 0; // Ustal odstęp dla drugiej linii

// const sourceOffsetX = sourceX + offset * normalX;
// const sourceOffsetY = sourceY + offset * normalY;

// const targetX: number = targetNode.position.x + targetNode.width / 2 + offset * normalX;
// const targetY: number = targetNode.position.y + targetNode.height / 2 + offset * normalY;

// const path: string = `M${sourceOffsetX},${sourceOffsetY} L${targetX},${targetY}`;
