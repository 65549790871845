import { memo, useMemo } from 'react';
import { Handle, Position, useStore } from 'reactflow';

// const sourceStyle = { zIndex: 1 };
// const targetStyle = { zIndex: 2 };

const connectionNodeIdSelector = (state) => state.connectionNodeId;

type CustomNodeType = {
  data: any;
};
function CustomNode({ data }: CustomNodeType) {
  const connectionNodeId = useStore(connectionNodeIdSelector);
  // const connectionNodeId = useMemo(() => useStore(connectionNodeIdSelector), []);

  const sourceStyle = useMemo(() => ({ zIndex: 1 }), []);
  const targetStyle = useMemo(() => ({ zIndex: 2 }), []);

  const isConnecting = !!connectionNodeId;

  return (
    <div className="custom-node">
      <div className="custom-node__body">
        <Handle className="customHandle" position={Position.Right} type="target" style={sourceStyle} id={`data-id-2`} />
        {!isConnecting && <Handle className="customHandle" position={Position.Left} style={targetStyle} type="source" id={`data-id-1`} />}
        {data.label}
      </div>
    </div>
  );
}

export default memo(CustomNode);
