import { useIntl } from 'react-intl';
import { Modal, Button } from 'Shared/components';

type DeleteStudyType = {
  test: any;
  onClose: () => void;
  handleDeleteStudy: (data) => void;
};
const localStorageForm = JSON.parse(sessionStorage.getItem('formTest'));

const DeleteStudy = ({ test, onClose, handleDeleteStudy }: DeleteStudyType) => {
  const { formatMessage } = useIntl();
  const handleDeleteClick = () => {
    if (localStorageForm?.testUuid === test.uuid) {
      sessionStorage.removeItem('formTest');
    }
    handleDeleteStudy({ testUuid: test.uuid });
  };
  return (
    <>
      <Modal className="delete-test-modal" isOpen={test} onClose={() => onClose()}>
        <div className="modal-heading">{formatMessage({ id: 'study_remove' })}</div>
        <div className="delete-test-modal__descript">
        {formatMessage({ id: 'study_remove_description' })} <span>{test.name}</span>?
        </div>
        <div className="delete-test-modal__buttons">
          <Button label={formatMessage({ id: 'cancel' })} stroke onClick={() => onClose()} />
          <Button label={formatMessage({ id: 'confirm' })} onClick={() => handleDeleteClick()} />
        </div>
      </Modal>
    </>
  );
};

export default DeleteStudy;
