import { useState, useEffect, useRef } from 'react';
import { SVG_TYPE, ROUTES, USER_ROLES } from 'Shared/enums';
import { SVG, Badge, Pagination } from 'Shared/components';
import { EditUserModal, SuspendUserModal } from '../../components';
import { useLocation, generatePath } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

type AdministratorsListType = {
  users: any;
  activeUserUuid: any;
  getUsers: (data) => void;
  editUser: (uuid, data) => void;
  suspendUser: (uuid, action) => void;
  resendActivateLink: (email) => void;
  role: USER_ROLES;
  setRole: (role: USER_ROLES) => void;
};

const AdministratorsList = ({
  activeUserUuid,
  users,
  editUser,
  suspendUser,
  resendActivateLink,
  getUsers,
  role,
  setRole,
}: AdministratorsListType) => {
  const isMobile = window.innerWidth < 1024;
  const [isEditModal, setIsEditModal] = useState(null);
  const [isSuspendUser, setIsSuspendUser] = useState({ uuid: false, action: '' });

  const [whichSettingsIsOpen, setWhichSettingsIsOpen] = useState(null);
  const [whichEmailIsOpen, setWhichEmailIsOpen] = useState(null);
  const menuRefs = useRef([]);

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const parts = pathname.split('/');
  const lastElement = parts.pop();
  const [currentPage, setCurrentPage] = useState(parseInt(lastElement));
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (currentPage.toString() !== lastElement) {
      setCurrentPage(parseInt(lastElement));
    }
  }, [lastElement]);

  const handleSettingsMenuOpen = (idx) => {
    setWhichSettingsIsOpen(whichSettingsIsOpen === idx ? null : idx);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      let isOutside = true;
      menuRefs.current.forEach((ref) => {
        if (ref && ref.contains(event.target)) {
          isOutside = false;
        }
      });
      if (isOutside) {
        setWhichSettingsIsOpen(null);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleEdit = (user) => {
    setIsEditModal(user);
  };

  const handleSuspend = (uuid, action) => {
    setIsSuspendUser({ uuid, action });
  };

  const handleResendActivateLink = async (email) => {
    await resendActivateLink(email);
  };

  const handleShowEmail = (idx) => {
    if (whichEmailIsOpen <= 0 || whichEmailIsOpen != idx) {
      setWhichEmailIsOpen(idx);
    } else {
      setWhichEmailIsOpen(null);
    }
  };

  useEffect(() => {
    changePage(1)
  },[role])

  useEffect(() => {
    getUsers({ page: currentPage, role: role });
  }, [currentPage, role]);

  const changePage = (page) => {
    setTimeout(() => {
      navigate({ pathname: generatePath(ROUTES.USERS, { page }) });
    }, 200);
  };

  return (
    <>
      <div className="administrators-list__roles">
        <span
          className={`administrators-list__role ${role === USER_ROLES.ADMIN ? 'administrators-list__role--active' : ''} `}
          onClick={() => setRole(USER_ROLES.ADMIN)}>
          {formatMessage({ id: 'admin' })}
        </span>
        <span
          className={`administrators-list__role ${role === USER_ROLES.EDITOR ? 'administrators-list__role--active' : ''} `}
          onClick={() => setRole(USER_ROLES.EDITOR)}>
          {formatMessage({ id: 'editor' })}
        </span>
      </div>
      <div className="administrators-list">
        <EditUserModal editUser={editUser} user={isEditModal} onClose={() => setIsEditModal(null)} />
        <SuspendUserModal
          action={isSuspendUser.action}
          userUuid={isSuspendUser.uuid}
          onClose={() => setIsSuspendUser({ uuid: false, action: '' })}
          suspendUser={suspendUser}
          role={role}
        />
        {isMobile ? (
          <div className="administrator-mobile-list">
            {users &&
              users?.data.length > 0 &&
              users?.data.map((item, idx) => {
                const isActive = idx === whichSettingsIsOpen;
                const showEmail = idx + 1 === whichEmailIsOpen;
                const showBadge = () => {
                  if (item.suspended) {
                    return <Badge label={formatMessage({ id: 'suspended' })} status="light" />;
                  } else {
                    if (item.activate) {
                      return <Badge label={formatMessage({ id: 'active1' })} status="bold" />;
                    } else {
                      return <Badge label={formatMessage({ id: 'inactive' })} status="medium" />;
                    }
                  }
                };
                return (
                  <div key={item.uuid + idx} className="administrator-mobile-list__item">
                    <div className="administrator-mobile-list__item-top">
                      <div className="administrator-mobile-list__item-onClick" onClick={() => handleShowEmail(idx + 1)} />
                      <div className="administrator-mobile-list__item-name">
                        {item.firstName} {item.lastName}
                      </div>
                      <div className="administrator-mobile-list__item-status">{showBadge()}</div>
                      <div className="administrator-mobile-list__item-dots" onClick={() => handleSettingsMenuOpen(idx)}>
                        <div
                          ref={(ref) => (menuRefs.current[idx] = ref)}
                          className={`administrator-list-settings ${isActive ? 'administrator-list-settings--active' : ''}`}>
                          <SVG type={SVG_TYPE.DOTS} />
                        </div>
                        <div className={`administrator-list-settings__content ${isActive ? 'administrator-list-settings__content--show' : ''}`}>
                          <div className="administrator-list-settings__label" onClick={() => handleEdit(item)}>
                            {formatMessage({ id: 'edit' })}
                          </div>
                          <div
                            className="administrator-list-settings__label"
                            onClick={() => handleSuspend(item.uuid, !item.suspended ? 'suspend' : 'restore')}>
                            {!item.suspended ? formatMessage({ id: 'suspend_user' }) : formatMessage({ id: 'restore_user' })}
                          </div>
                          {!item.activate && (
                            <div className="administrator-list-settings__label" onClick={() => handleResendActivateLink(item.email)}>
                              {formatMessage({ id: 'send_activation_link' })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={`administrator-mobile-list__item-bottom ${showEmail ? 'administrator-mobile-list__item-bottom--show' : ''}`}>
                      {item.email}
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="administrators-list__table">
            <div className="administrators-list__thead">
              <div className="administrators-list__table-list">
                <p>{formatMessage({ id: 'first_name' })}</p>
                <p>{formatMessage({ id: 'last_name' })}</p>
                <p>{formatMessage({ id: 'email' })}</p>
                <p>{formatMessage({ id: 'status' })}</p>
                <p></p>
              </div>
            </div>
            <div className="administrators-list__tbody">
              {users &&
                users?.data.length > 0 &&
                users?.data.map((item, idx) => {
                  const isActive = idx === whichSettingsIsOpen;
                  const showBadge = () => {
                    if (item.suspended) {
                      return <Badge label={formatMessage({ id: 'suspended' })} status="light" />;
                    } else {
                      if (item.activate) {
                        return <Badge label={formatMessage({ id: 'active1' })} status="bold" />;
                      } else {
                        return <Badge label={formatMessage({ id: 'inactive' })} status="medium" />;
                      }
                    }
                  };
                  return (
                    <div key={item.uuid + idx} className="administrators-list__tbody-list">
                      <p className="administrators-list__tbody-item">{item.firstName}</p>
                      <p className="administrators-list__tbody-item">{item.lastName}</p>
                      <p className="administrators-list__tbody-item">{item.email}</p>
                      <p className="administrators-list__tbody-item">{showBadge()}</p>
                      <div className="administrators-list__tbody-item" onClick={() => handleSettingsMenuOpen(idx)}>
                        <div className="administrator-list-settings-menu">
                          <div
                            ref={(ref) => (menuRefs.current[idx] = ref)}
                            className={`administrator-list-settings ${isActive ? 'administrator-list-settings--active' : ''}`}>
                            <SVG type={SVG_TYPE.DOTS} />
                          </div>
                          <div className={`administrator-list-settings__content ${isActive ? 'administrator-list-settings__content--show' : ''}`}>
                            <div className="administrator-list-settings__label" onClick={() => handleEdit(item)}>
                              {formatMessage({ id: 'edit' })}
                            </div>
                            {item.uuid !== activeUserUuid && (
                              <div
                                className="administrator-list-settings__label"
                                onClick={() => handleSuspend(item.uuid, !item.suspended ? 'suspend' : 'restore')}>
                                {!item.suspended ? formatMessage({ id: 'suspend_user' }) : formatMessage({ id: 'restore_user' })}
                              </div>
                            )}
                            {!item.activate && !item.suspended && (
                              <div className="administrator-list-settings__label" onClick={() => handleResendActivateLink(item.email)}>
                                {formatMessage({ id: 'send_activation_link' })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        {users && users.meta.last_page > 1 ? (
          <Pagination
            className="administrators-list__pagination"
            totalPages={users.meta.last_page}
            currentPage={users.meta.current_page}
            onPageChange={(page) => changePage(page)}
          />
        ) : null}
      </div>
    </>
  );
};

export default AdministratorsList;
