import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import zxcvbn from 'zxcvbn';

type P = {
  password: string;
  className?: string;
  isHidden?: boolean;
};

const PasswordStrengthChecker = ({ password, className = '', isHidden }: P) => {
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordStrengthValue, setPasswordStrengthValue] = useState('weak');
  const { formatMessage } = useIntl();

  const getPasswordStrength = () => {
    setPasswordStrength(zxcvbn(password).score);
  };

  useEffect(() => {
    getPasswordStrength();
  }, [password]);

  useEffect(() => {
    if (passwordStrength < 1) {
      setPasswordStrengthValue('weak');
    }
    if (passwordStrength > 1) {
      setPasswordStrengthValue('medium');
    }
    if (passwordStrength > 3) {
      setPasswordStrengthValue('strong');
    }
  }, [passwordStrength]);

  return (
    <div className={`password-strength-checker ${isHidden ? 'password-strength-checker--hidden' : ''} ${className}`}>
      <span className={`password-strength-checker__bar password-strength-checker__bar--${passwordStrengthValue}`}>
        <p className={`password-strength-checker__label ${passwordStrengthValue === 'weak' ? 'password-strength-checker__label--active' : ''}`}>
          {formatMessage({ id: 'weak' })}
        </p>
        <p className={`password-strength-checker__label  ${passwordStrengthValue === 'medium' ? 'password-strength-checker__label--active' : ''} `}>
          {formatMessage({ id: 'medium' })}
        </p>
        <p className={`password-strength-checker__label   ${passwordStrengthValue === 'strong' ? 'password-strength-checker__label--active' : ''}`}>
          {formatMessage({ id: 'strong' })}
        </p>
      </span>
      <span
        className={`password-strength-checker__bar ${passwordStrength > 1 ? `password-strength-checker__bar--${passwordStrengthValue}` : ''}`}></span>
      <span
        className={`password-strength-checker__bar ${passwordStrength > 3 ? `password-strength-checker__bar--${passwordStrengthValue}` : ''}`}></span>
    </div>
  );
};

export default PasswordStrengthChecker;
