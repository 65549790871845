import { useState } from 'react';
import { LoginContent, Input, Button } from 'Shared/components';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { MainType } from './Container';
import { useIntl } from 'react-intl';

const ForgottenPassword = (props: MainType) => {
  const [resendMail, setResendMail] = useState('');
  const { actions } = props;
  const { sendPasswordResetLink } = actions;
  const [isSend, setIsSend] = useState(false);
  const { formatMessage } = useIntl();

  const validation = () =>
    Yup.object().shape({
      email: Yup.string()
        .required(formatMessage({ id: 'validation.required' }))
        .email(formatMessage({ id: 'validation.email' })),
    });
  
  const handleSendMail = async ({ email }) => {
    const response = await sendPasswordResetLink(email);
    if (response) {
      setResendMail(email);
      localStorage.setItem('resendMail', email);
      setIsSend(true);
    }
  };

  return (
    <>
      {!isSend ? (
        <LoginContent
          goBack
          goBackFunc={() => window.history.back()}
          heading={formatMessage({ id: 'dont_remember_password' })}
          descript={formatMessage({ id: 'enter_email_to_reset_password' })}>
          <Formik
            validationSchema={validation}
            initialValues={{ email: '' }}
            validateOnBlur={false}
            enableReinitialize
            onSubmit={async (value) => handleSendMail(value)}>
            {({ handleSubmit, errors }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  label={formatMessage({ id: 'email' })}
                  name="email"
                  placeholder=""
                  required
                  component={Input}
                  treshold={0}
                  maxLength={32}
                  errors={errors}
                />
                <div className="login-content__button">
                  <Button label={formatMessage({ id: 'submit' })} type="submit" />
                </div>
              </form>
            )}
          </Formik>
        </LoginContent>
      ) : (
        <LoginContent
          goBack
          goBackFunc={() => setIsSend(false)}
          heading={formatMessage({ id: 'reset_password_mail_send' })}
          descript={formatMessage({ id: 'reset_password_mail_send_description' })}>
          <div className="login-page__email-did-not-arrive">{formatMessage({ id: 'mail_did_not_arrive' })}</div>
          <div className="login-content__button">
            <Button label={formatMessage({ id: 'send_again' })} type="submit" onClick={() => handleSendMail({ email: resendMail })} />
          </div>
        </LoginContent>
      )}
    </>
  );
};

export default ForgottenPassword;
