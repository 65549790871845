import { useEffect } from 'react';
import { LoginContent, Input, Button } from 'Shared/components';
import { ROUTES } from 'Shared/enums';
import { Field, Formik } from 'formik';
import { Link, useNavigate, generatePath } from 'react-router-dom';
import { MainType } from './Container';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';

const LoginPage = (props: MainType) => {
  const navigate = useNavigate();
  const { actions } = props;
  const { login, getAuthorizedUser } = actions;
  const { formatMessage } = useIntl();

  useEffect(() => {
    getAuthorizedUser()
      .then((response) => {
        response && navigate({ pathname: generatePath(ROUTES.STUDIES, { page: '1' }) });
      })
      .catch((error) => {
        error;
      });
  }, []);

  const handleLogin = async ({ email, password }) => {
    const response = await login(email, password);
    if (response) {
      navigate({ pathname: generatePath(ROUTES.STUDIES, { page: '1' }) });
    }
  };

  const validation = () =>
    Yup.object().shape({
      email: Yup.string().required(formatMessage({ id: 'validation.required' })).email(formatMessage({ id: 'validation.email' })),
      password: Yup.string().min(8, formatMessage({ id: 'validation.password_length' })).required(formatMessage({ id: 'validation.required' })),
    });
  return (
    <LoginContent heading={formatMessage({ id: 'login' })} buttonLabel={formatMessage({ id: 'login' })}>
      <Formik
        validationSchema={validation}
        initialValues={{ email: '', password: '' }}
        validateOnBlur={false}
        enableReinitialize={true}
        onSubmit={async (values) => handleLogin(values)}>
        {({ handleSubmit, errors }) => (
          <form onSubmit={handleSubmit}>
            <Field
              label={formatMessage({ id: 'email' })}
              name="email"
              placeholder=""
              required
              component={Input}
              treshold={0}
              maxLength={32}
              errors={errors}
            />
            <Field
              label={formatMessage({ id: 'password' })}
              name="password"
              placeholder=""
              required
              type="password"
              component={Input}
              treshold={0}
              maxLength={32}
              errors={errors}
              className="login-page__email"
            />
            <Link className="login-page__forget-password" to={ROUTES.FORGOTTEN_PASSWORD}>
              {formatMessage({ id: 'dont_remember_password' })}
            </Link>
            <div className="login-content__button">
              <Button label={formatMessage({ id: 'login' })} type="submit" />
            </div>
          </form>
        )}
      </Formik>
    </LoginContent>
  );
};

export default LoginPage;
