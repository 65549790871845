import { Button, SVG } from 'Shared/components';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { StudiesList } from './components';
import { useNavigate } from 'react-router';
import { MainType } from './Container';
import { useIntl } from 'react-intl';

// const modalType = {
//   ADD: 'add',
//   EDIT: 'edit',
//   DELETE: 'delete',
// };

const TestsPage = (props: MainType) => {
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 1024;
  const { actions, tests, translations } = props;
  const { getStudies, deleteStudy, storeCustomTranslations, getCustomTranslations } = actions;

  // const [modalManage, setModalManage] = useState('');
  // console.log(modalManage);

  const localStorageForm = JSON.parse(sessionStorage.getItem('formTest'));

  const handleRedirect = () => {
    navigate({ pathname: ROUTES.ADD_STUDY });
  };
  const { formatMessage } = useIntl();

  return (
    <div className="dashboard-content">
      <div className="dashboard-header">
        <div className="dashboard-heading">{formatMessage({ id: 'study' })}</div>
        {isMobile ? (
          <div className="administrator-page__add-user" onClick={() => navigate({ pathname: ROUTES.ADD_STUDY })}>
            <SVG type={SVG_TYPE.PLUS} />
          </div>
        ) : (
          <Button
            label={localStorageForm ? formatMessage({ id: 'complete' }) : formatMessage({ id: 'add_study' })}
            onClick={() => handleRedirect()}
          />
        )}
      </div>
      <StudiesList
        deleteStudy={deleteStudy}
        getStudies={getStudies}
        storeCustomTranslations={storeCustomTranslations}
        tests={tests}
        getCustomTranslations={getCustomTranslations}
        translations={translations}
      />
    </div>
  );
};

export default TestsPage;
