import { useState } from 'react';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

type BoardInfoType = {
  nodes: any;
  formValues: any;
  isMissingParameter: any;
  whichEdgeControlsIsOpen: any;
  isEdgeControlsOpened?: any;
  selectedNode?: any;
  translate: any;
};

const BoardInfo = ({ nodes, formValues, isMissingParameter, isEdgeControlsOpened, selectedNode, translate }: BoardInfoType) => {
  const [isOpen, setIsopen] = useState(true);

  function isObjectNotEmpty() {
    if (!formValues.hasOwnProperty('descript')) {
      return true;
    }
    for (const key in formValues) {
      if (key !== 'descript' && (formValues[key] === '' || formValues[key] === '0')) {
        return false;
      }
    }
    return true;
  }
  {
    // console.log(isMissingParameter, 'tu?');
  }

  return (
    <>
      <div className={`board-info__wrapper`}>
        <div className={`board-info ${!isOpen ? 'board-info--hidden' : ''}`}>
          {isOpen && (
            <>
              <div className="board-info__close-wrapper" onClick={() => setIsopen(false)}>
                {translate('close')}
                <SVG className="board-info__close" type={SVG_TYPE.CLOSE} />
              </div>
            </>
          )}
          <div className="board-info__icon-wrapper board-info__icon-wrapper--pointer" onClick={() => setIsopen(!isOpen)}>
            <SVG type={SVG_TYPE.INFO} />
          </div>
          {isOpen && (
            <div className="board-info__text">
              {isEdgeControlsOpened ? (
                <>
                  <p>{translate('arrow_indicates_influences')}</p>
                  <p>{translate('negative_influence')}</p>
                  <p>{translate('positive_influence')}</p>
                  <p>{translate('thickness_of_arrow')}</p>
                  <p>{translate('two_arrows_one_direction')}</p>
                </>
              ) : (
                <>
                  {nodes?.length <= 0 && <>{translate('your_map_is_empty')}</>}
                  {nodes?.length >= 1 && (
                    <>
                      {isObjectNotEmpty() ? (
                        <>
                          {nodes?.length >= 2 && !selectedNode ? (
                            <>
                              <p> {translate('add_additional_goal')}</p>
                              <p>{translate('to_add_connection')}</p>
                              <p>{translate('after_drawing_arrow')}</p>
                              <p>{translate('indicate_mutual_influence')}</p>
                            </>
                          ) : (
                            <>
                              {nodes?.length === 1 && !selectedNode ? (
                                <p>{translate('you_can_add_additional_goal')}</p>
                              ) : (
                                <>
                                  <p>{translate('name_and_describe_goal')}</p>
                                  <p>{translate('you_can_edit_goal')}</p>
                                  <p>{translate('you_can_change_goal_position')}</p>
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <p>{translate('name_and_describe_goal')}</p>
                          <p>{translate('you_can_edit_goal')}</p>
                          <p>{translate('you_can_change_goal_position')}</p>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {((isMissingParameter && !isObjectNotEmpty()) || (isMissingParameter && isEdgeControlsOpened)) && (
          <>
            <div className={`board-info `}>
              <div className="board-info__icon-wrapper">
                <SVG type={SVG_TYPE.WARNING_INFO} />
              </div>
              <div className="board-info__text board-info__text--error">{translate('to_close_goal_edit')}</div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BoardInfo;
