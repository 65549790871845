import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

type ValidationItemProps = {
  correct: boolean;
  label: string;
};

function ValidationItem({ correct, label }: ValidationItemProps) {
  return (
    <div className="validation-item">
      <SVG type={correct ? SVG_TYPE.CIRCLE_GREEN : SVG_TYPE.CIRCLE_RED} />
      <p>{label}</p>
    </div>
  );
}

type PasswordValidationProps = {
  password: string;
  className?: string;
  setIsValid?: (isValid: boolean) => void;
};

function PasswordValidation({ password, className = '', setIsValid }: PasswordValidationProps) {
  const { formatMessage } = useIntl();
  useEffect(() => {
    if (setIsValid) {
      setIsValid(/[A-Z]/.test(password) && /[a-z]/.test(password) && /[0-9]/.test(password) && password.replace(' ', '').length >= 8);
    }
  }, [password, setIsValid]);
  return (
    <div className={`validation-item__wrapper ${className}`}>
      <ValidationItem correct={/[A-Z]/.test(password)} label={formatMessage({ id: 'validation.one_capital' })} />
      <ValidationItem correct={/[a-z]/.test(password)} label={formatMessage({ id: 'validation.one_lower' })} />
      <ValidationItem correct={/[0-9]/.test(password)} label={formatMessage({ id: 'validation.one_number' })} />
      <ValidationItem correct={password.replace(' ', '').length >= 8} label={formatMessage({ id: 'validation.min_8' })} />
    </div>
  );
}
export default PasswordValidation;
