import { connect } from 'react-redux';
import ForgottenPassword from './Main';
import { bindActionCreators } from 'redux';
import { sendPasswordResetLink } from 'Store/Actions/Auth';

type ConnectedP = {
  changelogs: Array<any>;
};

type DispatchedP = {
  actions: {
    sendPasswordResetLink: (email) => any;
  };
};
const mapStateToProps = (state) => ({
  changelogs: state.changelogs,
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      sendPasswordResetLink: sendPasswordResetLink,
    },
    dispatch,
  ),
});
export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(ForgottenPassword);
