import * as UserModule from '../../../Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import { translatedMessage } from 'Shared/utils';
const init = () => ({
  type: User.REGISTER_USER_INIT,
});

const success = () => ({
  type: User.REGISTER_USER_SUCCESS,
});

const failure = () => ({
  type: User.REGISTER_USER_FAILURE,
});

const registerUser = (data) => async (dispatch) => {
  dispatch(init());
  const id = toast.loading(translatedMessage('toast.pending_register'));
  try {
    await UserModule.Connector.register(data);
    toast.update(id, {
      render: translatedMessage('toast.register'),
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    dispatch(success());
    return true;
  } catch (err: any) {
    dispatch(failure());
    toast.update(id, {
      render: translatedMessage('toast.register_error'),
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
    });
    return false;
  }
};

export default registerUser;
