import * as CustomTranslationsModule from 'Modules/CustomTranslationsModule';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { CustomTranslations } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import { translatedMessage } from 'Shared/utils';

export const success = (phonePrefixes) => ({
  type:CustomTranslations.STORE_CUSTOM_TRANSLATIONS_SUCCESS ,
  payload: {
    phonePrefixes,
  },
});

const failure = () => ({
  type: CustomTranslations.STORE_CUSTOM_TRANSLATIONS_FAILURE,
});

const storeCustomTranslations = (translations) => async (dispatch) => {
  const id = toast.loading(translatedMessage('toast.pending_store_custom_translation'));
  try {
    const jsonData = JSON.stringify(translations);
    const response = await CustomTranslationsModule.Connector.storeCustomTranslation(jsonData);
    dispatch(success(response.data.phonePrefixSerialize));
    toast.update(id, {
      render: translatedMessage('toast.store_custom_translation'),
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    return true;
  } catch (err: any) {
    dispatch(failure());
      toast.update(id, {
        render: translatedMessage('toast.store_custom_translation_error'),
        type: toast.TYPE.ERROR,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
      });
    
    return err.response.data.message || err.response.data;
  }
};

export default storeCustomTranslations;
