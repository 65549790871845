import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';
import * as AuthModule from 'Modules/AuthModule';

export function login(email, password): AxiosPromise<AuthModule.Types.LogInResponse> {
  return API.post('auth/login/admin', { email, password });
}
export function logout(): AxiosPromise<any> {
  return API.put('auth/logout');
  // config(null, { Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}` })
}

export function sendPasswordResetLink(email): AxiosPromise<AuthModule.Types.LogInResponse> {
  return API.post('auth/recover', { email });
}

export function resetPassword(password, token): AxiosPromise<AuthModule.Types.LogInResponse> {
  return API.put('auth/recover', { password, token });
}

export function setPassword(password, token): AxiosPromise<AuthModule.Types.LogInResponse> {
  return API.put('auth/activate', { password, activateToken: token });
}

export function getAuthorizedUser(): AxiosPromise<AuthModule.Types.AuthorizedUserData> {
  return API.get('auth/me');
}

export function getRefreshToken(refreshToken: string): AxiosPromise<any> {
  return API.post('/auth/refresh', { refreshToken: refreshToken });
}

export function changeCurrentUserPassword({ currentPassword, newPassword }): AxiosPromise<any> {
  return API.post('auth/password/change', { currentPassword, password: newPassword });
}

export function checkToken(token: string): AxiosPromise<any> {
  return API.get('auth/valid/token', { params: { token } });
}

export function resendActivateLink(email): AxiosPromise<any> {
  return API.post('auth/activate/resend-link', { email });
}

export function activateUser(data): AxiosPromise<any> {
  return API.put('auth/activate', data);
}

export function activateAccount(token: string): AxiosPromise<any> {
  return API.put('auth/activate/user', { activateToken: token });
}
