import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import * as fromAuthReducer from './AuthReducer';
import * as fromUserReducer from './UserReducer';
import * as fromStudyReducer from './StudyReducer';
import * as fromReportReducer from './ReportReducer';
import * as fromCustomTranslationsReducer from './CustomTranslationsReducer';
import { createBrowserHistory } from 'history';

export type RootState = {
  router: any;
  authStore: fromAuthReducer.State;
  userStore: fromUserReducer.State;
  studyStore: fromStudyReducer.State;
  reportStore: fromReportReducer.State;
  customTranslationsStore: fromCustomTranslationsReducer.State;
  
  // viewManagementStore: fromViewManagementReducer.State;
};

export const history = createBrowserHistory();

const rootReducer = combineReducers<RootState>({
  router: connectRouter(history),
  authStore: fromAuthReducer.authReducer,
  userStore: fromUserReducer.userReducer,
  studyStore: fromStudyReducer.studyReducer,
  reportStore: fromReportReducer.reportReducer,
  customTranslationsStore: fromCustomTranslationsReducer.customTranslationsReducer,
} as any);

const appReducer = (state: RootState, action) => {
  if (action.type === 'LOG_OUT_SUCCESS') {
    state.authStore = undefined;
    state.router = {
      router: { ...state.router },
    };
  }
  return rootReducer(state, action);
};

export default appReducer;
