import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

interface ModalProps {
  children: React.ReactNode;
  isOpen: any;
  className?: string;
  onClose?: () => void;
  onOpen?: () => void;
}

function Modal({ children, isOpen, onClose, className = '', onOpen }: ModalProps) {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      setIsModalOpen(false);
      onClose && onClose();
    }
  };

  useEffect(() => {
    setIsModalOpen(isOpen);
    if (isOpen && onOpen) {
      onOpen();
    }
  }, [isOpen]);

  return (
    <>
      <div
        onClick={(e) => handleOutsideClick(e)}
        className={`modal__backdrop ${className ? `modal__backdrop--${className}` : ''} ${isModalOpen ? 'modal__backdrop--open' : ''}`}>
        <div className={`modal ${className}`}>{children}</div>
      </div>
    </>
  );
}

export default (props: ModalProps) => ReactDOM.createPortal(<Modal {...props} />, document.querySelector('body'));
