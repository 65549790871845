import { useIntl } from 'react-intl';
import { Modal, Button } from 'Shared/components';

type DeleteMapModalType = {
  uuid: any;
  close: any;
  handleGetStudyAnswerList: any;
  deleteMap: any;
};

const DeleteMapModal = ({ uuid, close, handleGetStudyAnswerList, deleteMap }: DeleteMapModalType) => {
  const { formatMessage } = useIntl();
  const handleDeleteMap = async () => {
    await deleteMap(uuid);
    await handleGetStudyAnswerList();
    close();
  };

  return (
    <Modal
      className="top-bar__end-map-modal"
      isOpen={uuid}
      onClose={() => {
        close();
      }}>
      <div className="modal-heading">{formatMessage({ id: 'map_delete' })}</div>
      <div className="modal-description  modal-description--full">{formatMessage({ id: 'map_delete_confirm' })}</div>
      <div className="top-bar__end-map-modal__buttons">
        <Button label={formatMessage({ id: 'cancel' })} stroke onClick={() => close()} />
        <Button label={formatMessage({ id: 'delete' })} onClick={() => handleDeleteMap()} />
      </div>
    </Modal>
  );
};

export default DeleteMapModal;
