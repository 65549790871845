import { useState, useEffect, useRef } from 'react';
import { SVG, Badge, Pagination, Sort } from 'Shared/components';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { useNavigate } from 'react-router';
import { generatePath, useLocation } from 'react-router-dom';
import { DeleteStudy, EditStudyContentModal } from 'Screens/StudiesPage/components';
import copy from 'copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { SortStateType } from 'Shared/components/Sort/Sort';

type StudiesListType = {
  tests: any;
  getStudies: (data) => any;
  deleteStudy: (data) => void;
  storeCustomTranslations: (data) => any;
  getCustomTranslations: (uuid) => any;
  translations: any;
};


type SortFilterType = {
  sortBy: string;
  order: SortStateType;
};

const StudiesList = ({ tests, getStudies, deleteStudy, storeCustomTranslations, getCustomTranslations, translations }: StudiesListType) => {
  const [isActive, setIsActive] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [tooltipText, setTooltipText] = useState('hide');
  const [sort, setSort] = useState<SortFilterType>({ sortBy: 'createdAt', order: 'desc' });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(null);

  const isMobile = window.innerWidth < 1024;
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const parts = pathname.split('/');
  const lastElement = parts.pop();
  const [currentPage, setCurrentPage] = useState(parseInt(lastElement));
  const containerRef = useRef(null);
  const { formatMessage } = useIntl();
  
  useEffect(() => {
    if (isEditModalOpen) {
      getCustomTranslations(isEditModalOpen.uuid);
    }
  }, [isEditModalOpen]);

  const showBadge = (item) => {
    if (item.status === 'close') {
      return <Badge label={formatMessage({ id: 'closed' })} status="light" />;
    } else {
      if (item.status === 'active') {
        return <Badge label={formatMessage({ id: 'active' })} status="bold" />;
      } else if (item.status === 'new') {
        return <Badge label={formatMessage({ id: 'new' })} status="medium" />;
      }
    }
  };

  useEffect(() => {
    if (currentPage.toString() !== lastElement) {
      setCurrentPage(parseInt(lastElement));
    }
  }, [lastElement]);

  const changePage = (page) => {
    setShowDetails(null);
    setTimeout(() => {
      navigate({ pathname: generatePath(ROUTES.STUDIES, { page }) });
    }, 200);
  };

  useEffect(() => {
    getStudies({ page: currentPage, ...sort });
  }, [currentPage, sort]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isActive !== null) {
        if (!containerRef.current.contains(event.target)) {
          setIsActive(null);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isActive]);

  const handleOpenDeleteModal = (item) => {
    setIsDeleteModalOpen(item);
    setTimeout(() => {
      setIsActive(false);
    }, 50);
  };

  const handleDeleteStudy = async (uuid) => {
    await deleteStudy(uuid);
    setIsDeleteModalOpen(false);
    const response = await getStudies({ page: currentPage });
    if (response.meta.current_page > response.meta.last_page) {
      navigate({ pathname: generatePath(ROUTES.STUDIES, { page: response.meta.last_page }) });
    }
  };

  const showAnswers = (item) => {
    sessionStorage.setItem('study', JSON.stringify({ name: item.name, page: tests?.meta?.current_page || 1 }));
    navigate({ pathname: generatePath(ROUTES.ANSWERS_STUDY, { study: item.link, page: '1' }) });
  };

  const handleSetShowDetails = (idx) => {
    if (idx === showDetails) {
      setShowDetails(null);
    } else {
      setShowDetails(idx);
    }
  };

  const handleOnEdit = (uuid) => {
    sessionStorage.setItem('studiesListPage', JSON.stringify(currentPage));
    navigate({ pathname: generatePath(ROUTES.EDIT_STUDY, { uuid }) });
  };
  ReactTooltip.rebuild();
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [currentPage, tests]);

  const handleClickStudyPreview = (link) => {
    const url = `https://goalmapp.com/${link}`;
    window.open(url, '_blank');
  };

  return (
    <div className="studies-list">
      <EditStudyContentModal
        isOpen={!!isEditModalOpen}
        data={isEditModalOpen}
        onClose={() => setIsEditModalOpen(null)}
        formatMessage={formatMessage}
        onSuccess={(data) => storeCustomTranslations(data)}
        translations={translations}
      />
      <div ref={containerRef}>
        <DeleteStudy handleDeleteStudy={handleDeleteStudy} test={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} />
        {isMobile ? (
          <>
            <div className="test-mobile-list">
              {tests &&
                tests.data &&
                tests.data.length > 0 &&
                tests.data.map((item, idx) => {
                  const minOneAnswer = item.countMaps >= 1;
                  return (
                    <div key={item.uuid} className="test-mobile-list__item">
                      <div className="test-mobile-list__item-top">
                        <div className="test-mobile-list__item-onClick" onClick={() => handleSetShowDetails(idx)} />
                        <div className="test-mobile-list__item-name">{item.name}</div>
                        <div className="test-mobile-list__item-status">{showBadge(item)}</div>
                        <div className="test-mobile-list__item-dots" onClick={() => setIsActive(idx)}>
                          <div className={`administrator-list-settings ${isActive === idx ? 'administrator-list-settings--active' : ''}`}>
                            <SVG type={SVG_TYPE.DOTS} />
                          </div>
                          <div
                            className={`administrator-list-settings__content ${
                              isActive === idx ? 'administrator-list-settings__content--show' : ''
                            }`}>
                            <div className="administrator-list-settings__label" onClick={() => handleClickStudyPreview(item.link)}>
                              {formatMessage({ id: 'study_preview' })}
                            </div>
                            {minOneAnswer && (
                              <div className="administrator-list-settings__label" onClick={() => showAnswers(item)}>
                                {formatMessage({ id: 'see_answers' })}
                              </div>
                            )}
                            <div
                              className="administrator-list-settings__label"
                              onClick={() => {
                                handleOnEdit(item.uuid);
                              }}>
                              {formatMessage({ id: 'edit' })}
                            </div>
                            <div
                              className="administrator-list-settings__label"
                              onClick={() => {
                                setIsActive(null);
                                setIsEditModalOpen(item);
                              }}>
                              {formatMessage({ id: 'edit_content' })}
                            </div>

                            <div className="administrator-list-settings__label" onClick={() => handleOpenDeleteModal(item)}>
                              {formatMessage({ id: 'delete' })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`test-mobile-list__item-bottom ${showDetails === idx ? 'test-mobile-list__item-bottom--show' : ''}`}>
                        <div className="test-mobile-list__item-bottom--link">https://goalmapp.com/{item.link}</div>
                        <div className="test-mobile-list__item-bottom--numbers">
                          <div>
                            <p> {formatMessage({ id: 'all' })}:</p>
                            <span>{item.countMaps}</span>
                          </div>
                          <div>
                            <p> {formatMessage({ id: 'ended' })}:</p>
                            <span>{item.completedMaps}</span>
                          </div>
                        </div>
                        <div className="test-mobile-list__item-bottom--data">
                          <p>{formatMessage({ id: 'created_date' })}:</p>
                          <span>{moment(item.createdAt).format('DD/MM/YYYY')}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        ) : (
          <div className="tests-list__table">
            <div className="tests-list__thead">
              <div className="tests-list__table-list">
                  <p>
                  {formatMessage({ id: 'name' })} <Sort state={sort.sortBy === 'name' ? sort.order : null} onChange={(state) => setSort({sortBy:'name', order:state})  } />
                </p>
                  <p className="tests-list__table-list--status">{formatMessage({ id: 'status' })}</p>
                  <p className="tests-list__table-list--data">
                  {formatMessage({ id: 'created_date' })} <Sort state={sort.sortBy === 'createdAt' ? sort.order : null} onChange={(state) => setSort({sortBy:'createdAt', order:state})  } />
                </p>
                  <p className="tests-list__table-list--number">{formatMessage({ id: 'all' })}</p>
                  <p className="tests-list__table-list--number">{formatMessage({ id: 'ended' })}</p>
                  <p>{formatMessage({ id: 'link' })}</p>
                <p></p>
              </div>
            </div>
            <div className="tests-list__tbody">
              {tests &&
                tests.data &&
                tests.data.length > 0 &&
                tests.data.map((item, idx) => {
                  const minOneAnswer = item.countMaps >= 1;
                  return (
                    <>
                      <ReactTooltip id="copy" place="bottom" type="light" effect="solid" className="tooltip" getContent={() => tooltipText} />
                      <div key={item.uuid} className="tests-list__tbody-list">
                        <p className="tests-list__tbody-item tests-list__tbody-item--short">
                          <p>{item.name}</p>
                        </p>
                        <p className="tests-list__tbody-item tests-list__tbody-item--badge">{showBadge(item)}</p>
                        <p className="tests-list__tbody-item tests-list__tbody-item--data">{moment(item.createdAt).format('DD/MM/YYYY')}</p>
                        <p className="tests-list__tbody-item tests-list__tbody-item--number">{item.countMaps}</p>
                        <p className="tests-list__tbody-item tests-list__tbody-item--number">{item.completedMaps}</p>
                        <div className="tests-list__tbody-item tests-list__tbody-item--copy-element">
                          <p className="tests-list__tbody-item--short">
                            <p>https://goalmapp.com/{item.link}</p>
                          </p>
                          <div
                            className="tests-list__tbody-item--copy-icon"
                            data-tip
                            onMouseUp={() => setTooltipText(formatMessage({ id: 'click_to_copy' }))}
                            onMouseEnter={() => setTooltipText(formatMessage({ id: 'click_to_copy' }))}
                            onMouseOut={() => setTooltipText('')}
                            data-for={'copy'}
                            onClick={() => {
                              copy(`https://goalmapp.com/${item.link}`);
                              setTooltipText(formatMessage({ id: 'link_was_copied' }));
                            }}>
                            <SVG type={SVG_TYPE.COPY} />
                          </div>
                        </div>
                        <div className="administrators-list__tbody-item" onClick={() => setIsActive(idx)}>
                          <div className="administrator-list-settings-menu">
                            <div className={`administrator-list-settings ${isActive === idx ? 'administrator-list-settings--active' : ''}`}>
                              <SVG type={SVG_TYPE.DOTS} />
                            </div>
                            <div
                              className={`administrator-list-settings__content ${
                                isActive === idx ? 'administrator-list-settings__content--show' : ''
                              }`}>
                              <div className="administrator-list-settings__label" onClick={() => handleClickStudyPreview(item.link)}>
                                {formatMessage({ id: 'study_preview' })}
                              </div>
                              {minOneAnswer && (
                                <div className="administrator-list-settings__label" onClick={() => showAnswers(item)}>
                                  {formatMessage({ id: 'see_answers' })}
                                </div>
                              )}
                              <div className="administrator-list-settings__label" onClick={() => handleOnEdit(item.uuid)}>
                                {formatMessage({ id: 'edit' })}
                              </div>
                              <div
                                className="administrator-list-settings__label"
                                onClick={() => {
                                  setIsActive(false);
                                  setIsEditModalOpen(item);
                                }}>
                                {formatMessage({ id: 'edit_content' })}
                              </div>
                              <div className="administrator-list-settings__label" onClick={() => handleOpenDeleteModal(item)}>
                                {formatMessage({ id: 'delete' })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        )}
        {tests && tests.meta.last_page > 1 && tests.data.length > 0 ? (
          <Pagination
            className="tests-list__pagination"
            totalPages={tests.meta.last_page}
            currentPage={tests.meta.current_page}
            onPageChange={(page) => changePage(page)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default StudiesList;
