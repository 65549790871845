import * as AuthModule from 'Modules/AuthModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import * as AuthActionModel from '../Models/AuthActionModel';

const init = () => ({
  type: Auth.GET_AUTHORIZED_USER_INIT,
});

const success = (authorizedUserData: AuthModule.Types.AuthorizedUserData): AuthActionModel.GetAuthorizedUser => ({
  type: Auth.GET_AUTHORIZED_USER_SUCCESS,
  payload: {
    authorizedUserData,
  },
});

// const failure = (devicesError) => ({
//   type: Auth.GET_AUTHORIZED_USER_FAILURE,
//   payload: {
//     devicesError,
//   },
// });

const getAuthorizedUser = () => async (dispatch) => {
  dispatch(init());
  try {
    const response = await AuthModule.Connector.getAuthorizedUser();
    const role = response.data.user.role
    sessionStorage.setItem('userRole', JSON.stringify(role));
    dispatch(success(response.data));
    return response.data
  } catch (err: any) {
    // console.log(response)
    // if (err.response.status === 409) {
    // }
    // dispatch(failure(err.response.data));
  }
};

export default getAuthorizedUser;
