import { useState } from 'react';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { SVG } from 'Shared/components';
import { useNavigate } from 'react-router';
import { ChangePasswordModal } from '../index';
import { MobileMenu } from 'Screens/Dashboard/components';
import { useIntl } from 'react-intl';
import { Locale } from 'Shared/utils';

type DashboardTopBarType = {
  logout: () => void;
  changeCurrentAdminPassword: (currentPassword, password) => void;
  authorizedUser: any;
};

const DashboardTopBar = ({ logout, changeCurrentAdminPassword,authorizedUser }: DashboardTopBarType) => {
  const isMobile = window.innerWidth < 1024;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const handleLogoutUser = async () => {
    await logout();
    sessionStorage.removeItem('userRole');
    navigate({ pathname: ROUTES.LOGIN });
    document.location.reload();
  };

  const handleOpenModal = () => {
    setIsMenuOpen(false);
    setIsModalOpen(!isModalOpen);
  };

  const handleLanguageChange = (language) => {
    localStorage.setItem('translation', language);
    sessionStorage.removeItem('language');
    document.location.reload();
  };
  const translation = Locale();
  return (
    <>
      <ChangePasswordModal
        changeCurrentAdminPassword={changeCurrentAdminPassword}
        isModalOpen={isModalOpen}
        isModalClose={() => setIsModalOpen(false)}
      />
      {!isMobile ? (
        <div className="dashboard-top-bar">
          <div className="dashboard-top-bar__logo">{/* <SVG type={SVG_TYPE.GOALMAPP_WITHOUT_PADDING} /> */}</div>
          <div className="dashboard-top-bar-menu__wrapper">
            <>
              <div className="dashboard-top-bar__language">
                <select name="language" onChange={(value) => handleLanguageChange(value.target.value)}>
                  <option selected={translation === 'PL'} value="PL">
                    PL
                  </option>
                  <option selected={translation === 'EN'} value="EN">
                    EN
                  </option>
                </select>
              </div>
              <div className="dashboard-top-bar__user" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                {authorizedUser?.firstName} {authorizedUser?.lastName}  <SVG type={SVG_TYPE.CHEVRONE} />
              </div>
              <div className={`dashboard-top-bar-menu__content ${isMenuOpen ? 'dashboard-top-bar-menu__content--show' : ''}`}>
                <div className="dashboard-top-bar-menu__item" onClick={() => handleOpenModal()}>
                  {formatMessage({ id: 'change_password' })}
                </div>
                <div className="dashboard-top-bar-menu__item dashboard-top-bar-menu__item--logout" onClick={() => handleLogoutUser()}>
                  {formatMessage({ id: 'logout' })}
                </div>
              </div>
            </>
          </div>
        </div>
      ) : (
        <>
          <MobileMenu handleLogoutUser={handleLogoutUser} handleOpenModal={handleOpenModal} />
        </>
      )}
    </>
  );
};

export default DashboardTopBar;
