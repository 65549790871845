import * as StudyModule from '../../../Modules/StudyModule';
import { Study } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Study.GET_STUDIES_MAP_FILE_INIT,
});

const success = (files) => ({
  type: Study.GET_STUDIES_MAP_FILE_SUCCESS,
  payload: {
    data: files,
  },
});

const failure = () => ({
  type: Study.GET_STUDIES_MAP_FILE_FAILURE,
});

const getStudiesMapFile = (uuid) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await StudyModule.Connector.getStudiesMapFile(uuid);

    const fileName = 'mapa.png'; // Zmień na odpowiednią nazwę pliku
    const contentType = 'image/png'; // Ustaw typ zawartości na 'image/png'
    const blob = new Blob([response.data], { type: contentType });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    dispatch(success(response.data));
    return response;
  } catch (err: any) {
    dispatch(failure());
    return false;
  }
};

export default getStudiesMapFile;
