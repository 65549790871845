import { useEffect, useState } from 'react';
import { FieldProps } from 'formik';
import { default as RcSlider } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useIntl } from 'react-intl';

interface InputProps {
  label: string;
  name: string;
  type: string;

  placeholder?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  value?: string;
  secondValue?: string;
  removable?: boolean;
  maxLength?: number;
  id?: string;
  inputMode?: string;
  pattern?: string;
  variant?: string;
  descript?: string;
  marks?: any;

  //formik
  field: any;
  errors: any;
  form: any;
  touched?: any;
  onChange?: (value) => void;
  handleOnChange?: any;
  isOnBlurAction: boolean;
  editUser: (data) => void;
  onFocus: any;
  onBlur?: any;
  scaleLevel?: any;
}

function Slider({ scaleLevel, label, className = '', required, form, errors = {}, field, onChange, id, descript, marks }: InputProps & FieldProps) {
  const [key, setKey] = useState(Math.random());
  const { formatMessage } = useIntl();
  useEffect(() => {
    setKey(Math.random());
  }, [field.value]);
  const [marksHeight, setMarksHeight] = useState(null);

  const isEmpty = field?.value === '' || field.value === '0' || field?.value === 0;

  useEffect(() => {
    const slider = document.getElementsByClassName(`slider__wrapper--${field.name}`);
    const sliderMarks = slider && slider[0].getElementsByClassName('rc-slider-mark');
    const marks = sliderMarks && sliderMarks.length && sliderMarks[0].getElementsByClassName('rc-slider-mark-text');
    let maxHeight = 0;
    for (let i = 0; i < marks.length; i++) {
      const element = marks[i];
      const elementHeight = element.clientHeight;
      if (elementHeight > maxHeight) {
        maxHeight = elementHeight;
      }
    }
    setMarksHeight(maxHeight);
  }, [field.value, marks]);

  return (
    <>
      <div
        key={key}
        id={id}
        className={`slider__wrapper ${className} slider__wrapper--${field.name} ${
          form.touched[field.name] && errors[field.name] ? 'slider__wrapper--error' : ''
        }`}>
        <label className="slider__label" htmlFor={field.name}>
          {label} {required ? null : <p className="slider__optional">{`(${formatMessage({ id: 'optional' })})`}</p>}
        </label>
        <div className="slider__descript">{descript}</div>
        <RcSlider
          onAfterChange={(e) => {
            form.setFieldValue(field.name, e);
            onChange(e);
          }}
          onChange={(e) => {
            form.setFieldValue(field.name, e);
            onChange(e);
          }}
          marks={marks}
          dots
          step={1}
          defaultValue={field?.value || 0}
          max={scaleLevel || 5}
          min={1}
          className={form.touched[field.name] && errors[field.name] ? 'slider--error' : isEmpty ? 'slider--empty' : ''}
          style={{ height: `${marksHeight + 10}px` }}
        />
        {errors && form.touched && <div className="slider__error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>}
      </div>
    </>
  );
}

export default Slider;
