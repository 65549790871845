// FullscreenModal.tsx
import React, { FC, ReactNode } from 'react';
import { Transition } from 'react-transition-group';
import { createPortal } from 'react-dom';
import Button from '../Button';
import { SVG_TYPE } from 'Shared/enums';
import { translatedMessage } from 'Shared/utils';

interface FullscreenModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
}

const FullscreenModal: FC<FullscreenModalProps> = ({ isOpen, onClose, children, className = '' }) => {
  const duration = 300;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
  };

  const transitionStyles: { [key: string]: React.CSSProperties } = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  return createPortal(
    <Transition in={isOpen} timeout={duration} unmountOnExit>
      {(state) => (
        <div
          className="fullscreen-modal-overlay"
          onClick={onClose}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}>
          <Button
            className="button--stroke fullscreen-modal__close button--max-h-40"
            onClick={() => onClose()}
            label={translatedMessage('close')}
            svg_type={SVG_TYPE.CLOSE}
            iconOnLeft
          />
          <div className={`fullscreen-modal ${className}`} onClick={(e) => e.stopPropagation()}>
            <div className="fullscreen-modal-content">{children}</div>
          </div>
        </div>
      )}
    </Transition>,
    document.querySelector('.App') ?? document.body,
  );
};

export default FullscreenModal;
