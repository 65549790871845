import * as UserModule from '../../../Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: User.GET_USERS_INIT,
});

const success = (data) => ({
  type: User.GET_USERS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.GET_USERS_FAILURE,
});

const getRefreshToken = (form) => async (dispatch) => {
    dispatch(init());
    try {
      const {data} = await UserModule.Connector.getUsers(form);
      dispatch(success(data.usersSerialize));
    } catch (err: any) {
      dispatch(failure());
    }
  };

export default getRefreshToken;
