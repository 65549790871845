import { Formik } from 'formik';
import { Textarea } from 'Shared/components';
import FullscreenModal from 'Shared/components/FullscreenModal';
import { Field } from 'formik';
import { Button } from 'Shared/components';
import * as Yup from 'yup';
import { customGetTranslation } from 'Shared/utils';

interface EditStudyContentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: (data) => boolean;
  data?: any;
  formatMessage: any;
  translations: any;
}

const EditStudyContentModal: React.FC<EditStudyContentModalProps> = ({
  isOpen,
  onClose,
  formatMessage,
  data,
  onSuccess,
  translations,
}: EditStudyContentModalProps) => {
  const filterUnchangedValues = (initialValues, values) => {
    return Object.entries(values).filter(([key, value]) => value !== initialValues[key]);
  };

  const onSubmit = async (values: any) => {
    const translations = filterUnchangedValues(initialValues, values).map(([key, value]) => ({ key, value, testUuid: data.uuid }));
    const preparedData = { translations };
    console.log(filterUnchangedValues(initialValues, values));
    const result = await onSuccess(preparedData);
    if (result) {
      onClose();
    }
  };

  const translation = data?.language;
  const customFormatMessage = (id) =>
    translation === 'PL' ? customGetTranslation({ key: id, locale: 'pl' }) : customGetTranslation({ key: id, locale: 'en' });

  const validationSchema = Yup.object().shape({
    next: Yup.string().required(formatMessage({ id: 'validation.required' })),
    back: Yup.string().required(formatMessage({ id: 'validation.required' })),
    your_map_is_empty: Yup.string().required(formatMessage({ id: 'validation.required' })),
    add_new_goal: Yup.string().required(formatMessage({ id: 'validation.required' })),
    goal_edit: Yup.string().required(formatMessage({ id: 'validation.required' })),
    name_your_goal: Yup.string().required(formatMessage({ id: 'validation.required' })),
    enter_goal_name: Yup.string().required(formatMessage({ id: 'validation.required' })),
    describe_goal: Yup.string().required(formatMessage({ id: 'validation.required' })),
    delete_goal: Yup.string().required(formatMessage({ id: 'validation.required' })),
    save_goal: Yup.string().required(formatMessage({ id: 'validation.required' })),
    goal_delete: Yup.string().required(formatMessage({ id: 'validation.required' })),
    goal_delete_description: Yup.string().required(formatMessage({ id: 'validation.required' })),
    finish_map: Yup.string().required(formatMessage({ id: 'validation.required' })),
    delete: Yup.string().required(formatMessage({ id: 'validation.required' })),
    add_additional_goal: Yup.string().required(formatMessage({ id: 'validation.required' })),
    to_add_connection: Yup.string().required(formatMessage({ id: 'validation.required' })),
    after_drawing_arrow: Yup.string().required(formatMessage({ id: 'validation.required' })),
    indicate_mutual_influence: Yup.string().required(formatMessage({ id: 'validation.required' })),
    you_can_add_additional_goal: Yup.string().required(formatMessage({ id: 'validation.required' })),
    name_and_describe_goal: Yup.string().required(formatMessage({ id: 'validation.required' })),
    you_can_edit_goal: Yup.string().required(formatMessage({ id: 'validation.required' })),
    you_can_change_goal_position: Yup.string().required(formatMessage({ id: 'validation.required' })),
    to_close_goal_edit: Yup.string().required(formatMessage({ id: 'validation.required' })),
    thats_all: Yup.string().required(formatMessage({ id: 'validation.required' })),
    thank_you_for_study: Yup.string().required(formatMessage({ id: 'validation.required' })),
    back_to_system: Yup.string().required(formatMessage({ id: 'validation.required' })),
    cancel: Yup.string().required(formatMessage({ id: 'validation.required' })),
    close: Yup.string().required(formatMessage({ id: 'validation.required' })),
    submit: Yup.string().required(formatMessage({ id: 'validation.required' })),
    end_survey_description: Yup.string().required(formatMessage({ id: 'validation.required' })),
    end_survey: Yup.string().required(formatMessage({ id: 'validation.required' })),
    confirm: Yup.string().required(formatMessage({ id: 'validation.required' })),
  });

  const initialValue = (key) => {
    const translation = translations.find((t) => t.key === key);
    return translation?.value || customFormatMessage(key);
  };

  const initialValues = {
    next: initialValue('next'),
    back: initialValue('back'),
    your_map_is_empty: initialValue('your_map_is_empty'),
    add_new_goal: initialValue('add_new_goal'),
    goal_edit: initialValue('goal_edit'),
    name_your_goal: initialValue('name_your_goal'),
    enter_goal_name: initialValue('enter_goal_name'),
    describe_goal: initialValue('describe_goal'),
    delete_goal: initialValue('delete_goal'),
    save_goal: initialValue('save_goal'),
    goal_delete: initialValue('goal_delete'),
    goal_delete_description: initialValue('goal_delete_description'),
    finish_map: initialValue('finish_map'),
    delete: initialValue('delete'),
    add_additional_goal: initialValue('add_additional_goal'),
    to_add_connection: initialValue('to_add_connection'),
    after_drawing_arrow: initialValue('after_drawing_arrow'),
    indicate_mutual_influence: initialValue('indicate_mutual_influence'),
    you_can_add_additional_goal: initialValue('you_can_add_additional_goal'),
    name_and_describe_goal: initialValue('name_and_describe_goal'),
    you_can_edit_goal: initialValue('you_can_edit_goal'),
    you_can_change_goal_position: initialValue('you_can_change_goal_position'),
    to_close_goal_edit: initialValue('to_close_goal_edit'),
    thats_all: initialValue('thats_all'),
    thank_you_for_study: initialValue('thank_you_for_study'),
    back_to_system: initialValue('back_to_system'),
    cancel: initialValue('cancel'),
    close: initialValue('close'),
    submit: initialValue('submit'),
    end_survey_description: initialValue('end_survey_description'),
    end_survey: initialValue('end_survey'),
    confirm: initialValue('confirm'),

  };
  return (
    <FullscreenModal isOpen={isOpen} onClose={onClose}>
      <div className="edit-study-modal">
        <h1 className="edit-study-modal__title">{formatMessage({ id: 'edit_content' })}</h1>
        <h2 className="edit-study-modal__subtitle">{data?.name}</h2>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          validateOnBlur={false}
          enableReinitialize
          onSubmit={async (values) => onSubmit(values)}>
          {({ handleSubmit, errors }) => (
            <form onSubmit={handleSubmit}>
              <Field
                label={`Button ${formatMessage({ id: 'next' })}`}
                name="next"
                placeholder=""
                required
                component={Textarea}
                type="text"
                maxLength={32}
                errors={errors}
              />

              <Field
                label={`Button ${formatMessage({ id: 'back' })}`}
                name="back"
                placeholder=""
                required
                component={Textarea}
                type="text"
                maxLength={32}
                errors={errors}
              />

              <Field
                label={`Button ${formatMessage({ id: 'close' })}`}
                name="close"
                placeholder=""
                required
                component={Textarea}
                type="text"
                maxLength={32}
                errors={errors}
              />

              <Field
                label={`Button ${formatMessage({ id: 'submit' })}`}
                name="submit"
                placeholder=""
                required
                component={Textarea}
                type="text"
                maxLength={32}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'popup_text' })} 1`}
                name="your_map_is_empty"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`Button ${formatMessage({ id: 'add_new_goal' })}`}
                name="add_new_goal"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'goal_edit' })}`}
                name="goal_edit"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'name_your_goal' })}`}
                name="name_your_goal"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'enter_goal_name' })}`}
                name="enter_goal_name"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'describe_goal' })}`}
                name="describe_goal"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`Button ${formatMessage({ id: 'delete_goal' })}`}
                name="delete_goal"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`Button ${formatMessage({ id: 'save_goal' })}`}
                name="save_goal"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'goal_delete' })}`}
                name="goal_delete"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'goal_delete_description' })}`}
                name="goal_delete_description"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`Button ${formatMessage({ id: 'finish_map' })}`}
                name="finish_map"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`Button ${formatMessage({ id: 'delete' })}`}
                name="delete"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`Button ${formatMessage({ id: 'cancel' })}`}
                name="cancel"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
                />

              <Field
                label={`Button ${formatMessage({ id: 'confirm' })}`}
                name="confirm"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'popup_text' })} 2`}
                name="add_additional_goal"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'popup_text' })} 3`}
                name="to_add_connection"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'popup_text' })} 4`}
                name="after_drawing_arrow"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'popup_text' })} 4`}
                name="indicate_mutual_influence"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'popup_text' })} 5`}
                name="you_can_add_additional_goal"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'popup_text' })} 6`}
                name="name_and_describe_goal"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'popup_text' })} 7`}
                name="you_can_edit_goal"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'popup_text' })} 8`}
                name="you_can_change_goal_position"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'popup_text' })} 9`}
                name="to_close_goal_edit"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'thats_all' })}`}
                name="thats_all"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'thank_you_for_study' })}`}
                name="thank_you_for_study"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'back_to_system' })}`}
                name="back_to_system"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'end_survey' })}`}
                name="end_survey"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <Field
                label={`${formatMessage({ id: 'end_survey_description' })}`}
                name="end_survey_description"
                required
                component={Textarea}
                type="text"
                maxLength={1000}
                errors={errors}
              />

              <div className="edit-study-modal__buttons">
                <Button label={formatMessage({ id: 'cancel' })} stroke type="button" />
                <Button label={formatMessage({ id: 'save_changes' })} type="submit" />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </FullscreenModal>
  );
};

export default EditStudyContentModal;
