import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';
import * as Types from 'Shared/models/CustomTranslationsModel';

export type State = Types.CustomTranslationsStore;
export const initialState = {
  translations: [],
};

export function customTranslationsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CustomTranslations.STORE_CUSTOM_TRANSLATIONS_SUCCESS: {
      return {
        ...state,
      };
    }
    case ActionTypes.CustomTranslations.STORE_CUSTOM_TRANSLATIONS_FAILURE: {
      return {
        ...state,
      };
    }
    case ActionTypes.CustomTranslations.GET_CUSTOM_TRANSLATIONS_SUCCESS: {
      return {
        ...state,
        translations: action.payload.data,
      };
    }
    case ActionTypes.CustomTranslations.GET_CUSTOM_TRANSLATIONS_FAILURE: {
      return {
        ...state,
        translations: [],
      };
    }

    default: {
      return state;
    }
  }
}
