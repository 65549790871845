import { useEffect, useState } from 'react';
import { LoginContent, Input, Button, Checkbox, PasswordStrengthChecker, PasswordValidation } from 'Shared/components';
import { ROUTES } from 'Shared/enums';
import { Field, Formik } from 'formik';
import { useNavigate, generatePath } from 'react-router-dom';
import { MainType } from './Container';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { Locale } from 'Shared/utils';

const RegisterPage = (props: MainType) => {
  const navigate = useNavigate();
  const { actions } = props;
  const { register, getAuthorizedUser } = actions;
  const { formatMessage } = useIntl();
  const [showValidation, setShowValidation] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  useEffect(() => {
    getAuthorizedUser()
      .then((response) => {
        response && navigate({ pathname: generatePath(ROUTES.STUDIES, { page: '1' }) });
      })
      .catch((error) => {
        error;
      });
  }, []);

  const handleRegister = async (values) => {
    const response = await register(values);
    if (response) {
      navigate({ pathname: ROUTES.ACTIVATE_EDITOR_PAGE });
    }
  };

  const validation = () =>
    Yup.object().shape({
      email: Yup.string()
        .required(formatMessage({ id: 'validation.required' }))
        .email(formatMessage({ id: 'validation.email' })),
      firstName: Yup.string().required(formatMessage({ id: 'validation.required' })),
      lastName: Yup.string().required(formatMessage({ id: 'validation.required' })),
      password: Yup.string()
        .min(8, formatMessage({ id: 'validation.password_length' }))
        .required(formatMessage({ id: 'validation.required' }))
        .test('Capital Letter', '', function (value) {
          return /[A-Z]/.test(value);
        })
        .test('Lowercase Letter', '', function (value) {
          return /[a-z]/.test(value);
        })
        .test('Number', '', function (value) {
          return /[0-9]/.test(value);
        })
        .test('cannot-start-with-space', formatMessage({ id: 'validation.cannot_start_with_space' }), function (value) {
          return !value?.startsWith(' ');
        }),
      acceptance: Yup.boolean().oneOf([true], formatMessage({ id: 'validation.required' })),
    });

  const handleBlur = async () => {
    isPasswordValid && setShowValidation(false);
  };
  return (
    <LoginContent heading={formatMessage({ id: 'register' })} buttonLabel={formatMessage({ id: 'register' })}>
      <Formik
        validationSchema={validation}
        initialValues={{ email: '', password: '', firstName: '', lastName: '', acceptance: false, language: Locale() }}
        validateOnBlur={false}
        enableReinitialize={true}
        onSubmit={async (values) => handleRegister(values)}>
        {({ handleSubmit, errors, values }) => (
          <form onSubmit={handleSubmit}>
            <Field
              label={formatMessage({ id: 'first_name' })}
              name="firstName"
              placeholder=""
              required
              component={Input}
              treshold={0}
              maxLength={32}
              errors={errors}
            />
            <Field
              label={formatMessage({ id: 'last_name' })}
              name="lastName"
              placeholder=""
              required
              component={Input}
              treshold={0}
              maxLength={32}
              errors={errors}
            />
            <Field
              label={formatMessage({ id: 'email' })}
              name="email"
              placeholder=""
              required
              component={Input}
              treshold={0}
              maxLength={32}
              errors={errors}
            />
            <Field
              label={formatMessage({ id: 'password' })}
              name="password"
              placeholder=""
              required
              component={Input}
              treshold={0}
              type="password"
              maxLength={32}
              inputOnFocus={() => setShowValidation(true)}
              onBlur={() => {
                handleBlur();
              }}
              errors={errors}
            />
            <PasswordStrengthChecker isHidden={!showValidation} password={values.password} />
            <PasswordValidation
              setIsValid={(value: boolean) => setIsPasswordValid(value)}
              className={` ${showValidation ? 'validation-item__wrapper--show' : ''}`}
              password={values.password}
            />

            <Field
              label={
                <>
                  {formatMessage({ id: 'register_acceptance1' })} <a>{formatMessage({ id: 'register_acceptance2' })}</a>{' '}
                  {formatMessage({ id: 'register_acceptance3' })} <a>{formatMessage({ id: 'register_acceptance4' })}</a>
                </>
              }
              name="acceptance"
              placeholder=""
              required
              type="checkbox"
              component={Checkbox}
              treshold={0}
              maxLength={32}
              errors={errors}
              className="login-page__email"
            />

            <div className="login-content__button">
              <Button label={formatMessage({ id: 'register' })} type="submit" />
            </div>
          </form>
        )}
      </Formik>
    </LoginContent>
  );
};

export default RegisterPage;
