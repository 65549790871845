import { Modal, Button, Input } from 'Shared/components';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';

type EditUserModalType = {
  user: any;
  onClose: any;
  editUser: (uuid, data) => void;
};

const EditUserModal = ({ user, onClose, editUser }: EditUserModalType) => {
  const { formatMessage } = useIntl();
  
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(formatMessage({ id: 'validation.required' })).email(formatMessage({ id: 'validation.email' })),
    lastName: Yup.string()
      .required(formatMessage({ id: 'validation.required' }))
      .min(3, formatMessage({ id: 'validation.min_3' }))
      .matches(/^[^\s].*[^\s]$/, formatMessage({ id: 'validation.cannot_start_and_end_with_space' })),
    firstName: Yup.string()
      .required(formatMessage({ id: 'validation.required' }))
      .min(3, formatMessage({ id: 'validation.min_3' }))
      .matches(/^[^\s].*[^\s]$/, formatMessage({ id: 'validation.cannot_start_and_end_with_space' })),
  });

  const handleEditAdministrator = (values) => {
    editUser(user.uuid, values);
    onClose();
  };

  return (
    <Modal className="add-administrator-modal" isOpen={user} onClose={() => onClose()}>
      <div className="modal-heading">{formatMessage({ id: 'edit_admin' })}</div>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ firstName: user?.firstName, lastName: user?.lastName, email: user?.email }}
        validateOnBlur={false}
        enableReinitialize
        onSubmit={async (values) => handleEditAdministrator(values)}>
        {({ handleSubmit, errors }) => (
          <form className="add-administrator-modal__form" onSubmit={handleSubmit}>
            <Field
              maxLength={30}
              label={formatMessage({ id: 'first_name' })}
              name="firstName"
              placeholder=""
              required
              component={Input}
              errors={errors}
            />
            <Field maxLength={30} label={formatMessage({ id: 'last_name' })} name="lastName" placeholder="" required component={Input} errors={errors} />
            <Field
              label={formatMessage({ id: 'email' })}
              name="email"
              placeholder=""
              required
              component={Input}
              treshold={0}
              maxLength={30}
              errors={errors}
            />
            <div className="add-administrator-modal__buttons">
              <Button label={formatMessage({ id: 'cancel' })} stroke onClick={() => onClose()} />
              <Button label={formatMessage({ id: 'save' })} onClick={() => handleSubmit()} />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditUserModal;
