import { connect } from 'react-redux';
import AdministratorsPage from './Main';
import { bindActionCreators } from 'redux';
import { resendActivateLink } from 'Store/Actions/Auth';
import { getUsers, addUser, editUser, suspendUser, } from 'Store/Actions/User';
import { getUsers as getUsersSelector } from 'Store/Selectors/UserSelector';
import { authorizedUser as authorizedUserSelector } from 'Store/Selectors/AuthSelector';


type ConnectedP = {
  users: any;
  authorizedUser: any;
};

type DispatchedP = {
  actions: {
    getUsers: (data) => any;
    addUser: (data) => any;
    editUser: (uuid, data) => any;
    suspendUser: (uuid, action) => any;
    resendActivateLink: (email) => any;
  };
};
const mapStateToProps = (state) => ({
  users: getUsersSelector(state),
  authorizedUser: authorizedUserSelector(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getUsers: getUsers,
      addUser: addUser,
      editUser: editUser,
      suspendUser: suspendUser,
      resendActivateLink: resendActivateLink
    },
    dispatch,
  ),
});
export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(AdministratorsPage);
