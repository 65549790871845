import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';

export function getUsers({ page, role }): AxiosPromise {
  return API.get('users', { params: { page, role } });
}

export function addUser(data): AxiosPromise {
  return API.post('users/admin', { ...data });
}

export function editUser(uuid, data): AxiosPromise {
  return API.put(`users/${uuid}`, { ...data });
}

export function suspendUser(uuid): AxiosPromise {
  return API.put(`users/suspended/${uuid}`);
}

export function register(data): AxiosPromise {
  return API.post(`users/create`, data);
}
