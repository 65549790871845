import * as StudyModule from '../../../Modules/StudyModule';
import { Study } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Study.GET_STUDY_ANSWER_LIST_INIT,
});

const success = (files) => ({
  type: Study.GET_STUDY_ANSWER_LIST_SUCCESS,
  payload: {
    data: files,
  },
});

const failure = () => ({
  type: Study.GET_STUDY_ANSWER_LIST_FAILURE,
});

const getStudyAnswerList = (data) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await StudyModule.Connector.getStudyAnswerList(data);
    dispatch(success(response.data.mapsStudies));
    return response.data.mapsStudies;
  } catch (err: any) {
    dispatch(failure());
    return false;
  }
};

export default getStudyAnswerList;
