const Locale = () => {
  if (sessionStorage.getItem('language') === 'undefined') {
    sessionStorage.removeItem('language');
  }
  if (localStorage.getItem('translation') === 'undefined') {
    localStorage.removeItem('translation');
  }
  const newLocale = sessionStorage.getItem('language') || localStorage.getItem('translation') || 'PL';
  return newLocale;
};

export default Locale;
