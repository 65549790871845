import * as StudyModule from '../../../Modules/StudyModule';
import { Study } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Study.GET_STUDY_FOR_EDIT_INIT,
});

const success = (files) => ({
  type: Study.GET_STUDY_FOR_EDIT_SUCCESS,
  payload: {
    data: files,
  },
});

const failure = () => ({
  type: Study.GET_STUDY_FOR_EDIT_FAILURE,
});

const getStudyForEdit = (data) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await StudyModule.Connector.getStudyForEdit(data);
    dispatch(success(response.data));
    return response.data;
  } catch (err: any) {
    dispatch(failure());
    return false;
  }
};

export default getStudyForEdit;
