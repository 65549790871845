import { useStoreApi, useReactFlow } from 'reactflow';

const FocusedNode = () => {
  const store = useStoreApi();
  const { setCenter } = useReactFlow();

  const focusNode = () => {
    const { nodeInternals } = store.getState();
    const nodes = Array.from(nodeInternals).map(([, node]) => node);
    if (nodes.length > 0) {
      const node = nodes[nodes?.length - 1];

      const x = node.position.x + node.width / 2;
      const y = node.position.y + node.height / 2;
      const zoom = 1;

      setCenter(x, y, { zoom, duration: 1000 });
      // setCenter(x, y, { zoom, duration: 1000 });
    }
  };

  return <div id="focused-node" onClick={() => focusNode()} />;
};

export default FocusedNode;
