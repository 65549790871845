export enum Auth {
  LOG_IN_INIT = 'LOG_IN_INIT',
  LOG_IN_SUCCESS = 'LOG_IN_SUCCESS',
  LOG_IN_FAILURE = 'LOG_IN_FAILURE',
  LOG_OUT_INIT = 'LOG_OUT_INIT',
  LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS',
  LOG_OUT_FAILURE = 'LOG_OUT_FAILURE',
  GET_AUTHORIZED_USER_INIT = 'GET_AUTHORIZED_USER_INIT',
  GET_AUTHORIZED_USER_SUCCESS = 'GET_AUTHORIZED_USER_SUCCESS',
  GET_AUTHORIZED_USER_FAILURE = 'GET_AUTHORIZED_USER_FAILURE',
  SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN',
  // SEND_PASSWORD_RESET_LINK_INIT = 'SEND_PASSWORD_RESET_LINK_INIT',
  SEND_PASSWORD_RESET_LINK_SUCCESS = 'SEND_PASSWORD_RESET_LINK_SUCCESS',
  SEND_PASSWORD_RESET_LINK_FAILURE = 'SEND_PASSWORD_RESET_LINK_FAILURE',
  GET_REFRESH_TOKEN_INIT = 'GET_REFRESH_TOKEN_INIT',
  GET_REFRESH_TOKEN_SUCCESS = 'GET_REFRESH_TOKEN_SUCCESS',
  GET_REFRESH_TOKEN_FAILURE = 'GET_REFRESH_TOKEN_FAILURE',
  CHECK_TOKEN_FAILURE = 'CHECK_TOKEN_FAILURE',
  CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS',
  RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS',
  RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE',
  CHANGE_CURRENT_USER_PASSWORD_FAILURE = 'CHANGE_CURRENT_USER_PASSWORD_FAILURE',
  CHANGE_CURRENT_USER_PASSWORD_SUCCESS = 'CHANGE_CURRENT_USER_PASSWORD_SUCCESS',  
  RESEND_ACTIVATE_LINK_FAILURE = 'RESEND_ACTIVATE_LINK_FAILURE',
  RESEND_ACTIVATE_LINK_SUCCESS = 'RESEND_ACTIVATE_LINK_SUCCESS',
}


export enum User { 
  GET_USERS_INIT = 'GET_USERS_INIT',
  GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE = 'GET_USERS_FAILURE',

  ADD_USER_INIT = 'ADD_USER_INIT',
  ADD_USER_SUCCESS = 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE = 'ADD_USER_FAILURE',

  EDIT_USER_INIT = 'EDIT_USER_INIT',
  EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILURE = 'EDIT_USER_FAILURE',

  SUSPEND_USER_INIT = 'SUSPEND_USER_INIT',
  SUSPEND_USER_SUCCESS = 'SUSPEND_USER_SUCCESS',
  SUSPEND_USER_FAILURE = 'SUSPEND_USER_FAILURE',

  REGISTER_USER_INIT = 'REGISTER_USER_INIT',
  REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE',
}
export enum Study { 
  ADD_STUDY_INIT = 'ADD_STUDY_INIT',
  ADD_STUDY_SUCCESS = 'ADD_STUDY_SUCCESS',
  ADD_STUDY_FAILURE = 'ADD_STUDY_FAILURE',

  GET_STUDIES_INIT = 'GET_STUDIES_INIT',
  GET_STUDIES_SUCCESS = 'GET_STUDIES_SUCCESS',
  GET_STUDIES_FAILURE = 'GET_STUDIES_FAILURE',

  GET_STUDY_FILES_INIT = 'GET_STUDY_FILES_INIT',
  GET_STUDY_FILES_SUCCESS = 'GET_STUDY_FILES_SUCCESS',
  GET_STUDY_FILES_FAILURE = 'GET_STUDY_FILES_FAILURE',

  DELETE_STUDY_INIT = 'DELETE_STUDY_INIT',
  DELETE_STUDY_SUCCESS = 'DELETE_STUDY_SUCCESS',
  DELETE_STUDY_FAILURE = 'DELETE_STUDY_FAILURE',

  ADD_STUDY_FILE_INIT = 'ADD_STUDY_FILE_INIT',
  ADD_STUDY_FILE_SUCCESS = 'ADD_STUDY_FILE_SUCCESS',
  ADD_STUDY_FILE_FAILURE = 'ADD_STUDY_FILE_FAILURE',

  DELETE_STUDY_FILE_INIT = 'DELETE_STUDY_FILE_INIT',
  DELETE_STUDY_FILE_SUCCESS = 'DELETE_STUDY_FILE_SUCCESS',
  DELETE_STUDY_FILE_FAILURE = 'DELETE_STUDY_FILE_FAILURE',

  GET_STUDY_BY_LINK_INIT = 'GET_STUDY_BY_LINK_INIT',
  GET_STUDY_BY_LINK_SUCCESS = 'GET_STUDY_BY_LINK_SUCCESS',
  GET_STUDY_BY_LINK_FAILURE = 'GET_STUDY_BY_LINK_FAILURE',

  SEND_STUDY_FORM_INIT = 'SEND_STUDY_FORM_INIT',
  SEND_STUDY_FORM_SUCCESS = 'SEND_STUDY_FORM_SUCCESS',
  SEND_STUDY_FORM_FAILURE = 'SEND_STUDY_FORM_FAILURE',

  GET_STUDY_ANSWER_LIST_INIT = 'GET_STUDY_ANSWER_LIST_INIT',
  GET_STUDY_ANSWER_LIST_SUCCESS = 'GET_STUDY_ANSWER_LIST_SUCCESS',
  GET_STUDY_ANSWER_LIST_FAILURE = 'GET_STUDY_ANSWER_LIST_FAILURE',

  GET_STUDY_FOR_EDIT_INIT = 'GET_STUDY_FOR_EDIT_INIT',
  GET_STUDY_FOR_EDIT_SUCCESS = 'GET_STUDY_FOR_EDIT_SUCCESS',
  GET_STUDY_FOR_EDIT_FAILURE = 'GET_STUDY_FOR_EDIT_FAILURE',

  EDIT_STUDY_FORM_INIT = 'EDIT_STUDY_FORM_INIT',
  EDIT_STUDY_FORM_SUCCESS = 'EDIT_STUDY_FORM_SUCCESS',
  EDIT_STUDY_FORM_FAILURE = 'EDIT_STUDY_FORM_FAILURE',

  GET_USER_IS_ACTIVE_INIT = 'GET_USER_IS_ACTIVE_INIT',
  GET_USER_IS_ACTIVE_SUCCESS = 'GET_USER_IS_ACTIVE_SUCCESS',
  GET_USER_IS_ACTIVE_FAILURE = 'GET_USER_IS_ACTIVE_FAILURE',

  DELETE_MAP_INIT = 'DELETE_MAP_INIT',
  DELETE_MAP_SUCCESS = 'DELETE_MAP_SUCCESS',
  DELETE_MAP_FAILURE = 'DELETE_MAP_FAILURE',

  GET_STUDIES_MAP_FILE_INIT = 'GET_STUDIES_MAP_FILE_INIT',
  GET_STUDIES_MAP_FILE_SUCCESS = 'GET_STUDIES_MAP_FILE_SUCCESS',
  GET_STUDIES_MAP_FILE_FAILURE = 'GET_STUDIES_MAP_FILE_FAILURE',
}


export enum Report { 
  GET_MAP_REPORT_INIT = 'GET_MAP_REPORT_INIT',
  GET_MAP_REPORT_SUCCESS = 'GET_MAP_REPORT_SUCCESS',
  GET_MAP_REPORT_FAILURE = 'GET_MAP_REPORT_FAILURE',
  GET_GROUP_REPORT_INIT = 'GET_GROUP_REPORT_INIT',
  GET_GROUP_REPORT_SUCCESS = 'GET_GROUP_REPORT_SUCCESS',
  GET_GROUP_REPORT_FAILURE = 'GET_GROUP_REPORT_FAILURE',
}

export enum CustomTranslations {
  STORE_CUSTOM_TRANSLATIONS_SUCCESS = 'STORE_CUSTOM_TRANSLATIONS_SUCCESS',
  STORE_CUSTOM_TRANSLATIONS_FAILURE = 'STORE_CUSTOM_TRANSLATIONS_FAILURE',
  GET_CUSTOM_TRANSLATIONS_SUCCESS = 'GET_CUSTOM_TRANSLATIONS_SUCCESS',
  GET_CUSTOM_TRANSLATIONS_FAILURE = 'GET_CUSTOM_TRANSLATIONS_FAILURE',
}