import { useCallback } from 'react';

const useTranslation = (formatMessage, translations) => {
  const customTranslation = useCallback(
    (key) => {
      const translated = translations.find((translation) => translation.key === key);
      if (!translated) {
        return formatMessage({ id: key });
      }
      return translated.value;
    },
    [formatMessage, translations],
  );

  return customTranslation;
};

export default useTranslation;
