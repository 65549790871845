import React from 'react';
import animationDataBlue from 'Assets/file/loader-animation.json';
import animationDataWhite from 'Assets/file/loader-animation-white.json';
import Lottie from 'react-lottie';

export default function Loader({ width, height, isWhite = false }: any) {
  const animationData = isWhite ? animationDataWhite : animationDataBlue;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const style = {
    width: width || '24',
    height: height || '24',
  };
  return (
    <div className="loader m-auto">
      <Lottie style={style} options={defaultOptions} />
    </div>
  );
}
