import * as TestModule from '../../../Modules/StudyModule';
import { Study } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Study.DELETE_STUDY_INIT,
});

const success = () => ({
  type: Study.DELETE_STUDY_SUCCESS,
});

const failure = () => ({
  type: Study.DELETE_STUDY_FAILURE,
});

const getNewStudyUuid = () => async (dispatch) => {
  dispatch(init());
  try {
    const { data } = await TestModule.Connector.getNewStudyUuid();
    dispatch(success());
    return data.testUuid;
  } catch (err: any) {
    dispatch(failure());
  }
};

export default getNewStudyUuid;
