import React, { useEffect, useRef, useCallback, useState, useMemo } from 'react';
import ReactFlow, { Controls, Background, useNodesState, useEdgesState, addEdge, ReactFlowProvider, MarkerType } from 'reactflow';
import { SidebarTarget, TopBar } from 'Shared/components';
import 'reactflow/dist/style.css';
import { CustomNode, EdgeControls, FocusedNode, BoardInfo, FloatingEdge } from './components';
import debounce from 'lodash.debounce';
// eslint-disable-next-line
import { SmartBezierEdge } from '@tisoap/react-flow-smart-edge';
import moment from 'moment';
import { useTranslation } from 'Shared/utils';

type BoardTargetType = {
  parameters: any;
  sendStudyForm: any;
  study: any;
  setScreenLastPage: any;
  addFileMap: any;
  translations: any;
  formatMessage: any;
};

const defaultEdgeOptions = {
  markerEnd: {
    type: MarkerType.Arrow,
    color: 'black',
    width: 60,
    height: 60,
    strokeWidth: 0.5,
  },
  style: { strokeWidth: 0.5, stroke: 'black' },
  type: 'floating',
};

const connectionLineStyle = {
  strokeWidth: 0.5,
  stroke: 'black',
};

const initialStorage = JSON.parse(sessionStorage.getItem('initialNodes'));
const initialStorageEdges = JSON.parse(sessionStorage.getItem('initialEdges'));

// sessionStorage.removeItem('initialEdges');
// sessionStorage.removeItem('initialNodes');

const initialNodes = initialStorage || [];
const initialEdges = initialStorageEdges || [];

// const edgeTypes: any = {
//   floating: FloatingEdge,
// };

const BoardTarget = ({ parameters, sendStudyForm, study, setScreenLastPage, addFileMap, formatMessage }: BoardTargetType) => {
  const nodeTypes = useMemo(() => ({ custom: CustomNode }), []);
  const containerRef = useRef(null);
  const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });
  const [selectedNode, setSelectedNode] = useState(null);
  const boardTargetClass = `board-target ${selectedNode ? 'board-target--active' : ''}`;
  const [initialValues, setInitialValues] = useState({});
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [isMissingParameter, setIsMissingParameter] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [whichNodeIsEdited, setWhichNodeIsEdited] = useState(null);
  const [whichEdgeControlsIsOpen, setWhichEdgeControlsIsOpen] = useState(null);
  const [isEdgeControlsOpened, setIsEdgeControlsOpened] = useState(false);
  const [isEdgeSliderOpen, setIsEdgeSliderOpen] = useState(false);

  const edgeTypes = {
    floating: (props) => <FloatingEdge edges={edges} {...props} />,
  };

  useEffect(() => {
    if (isEdgeControlsOpened) {
      // handleDeleteNode();
      // setWhichNodeIsEdited(null);
      const updatedNodes = nodes.map((item) => {
        if (item.className.includes('node-selected-element')) {
          const classes = item.className.split(' ');
          const updatedClasses = classes.filter((className) => className !== 'node-selected-element');
          item.className = updatedClasses.join(' ');
        }
        return item;
      });
      setNodes(updatedNodes);
    }
  }, [isEdgeControlsOpened]);

  useEffect(() => {
    if (nodes.length > 0) {
      const lastNode = nodes[nodes.length - 1];
      lastNode.data.label === '' && setSelectedNode(lastNode);
    }
  }, []);

  useEffect(() => {
    const initialValues = parameters.reduce((acc, item) => {
      const key = item.graphicChange;
      const value = selectedNode?.data?.hasOwnProperty(key) ? selectedNode.data[key] : '';

      if (key in acc) {
        let index = 1;
        while (key + '_' + index in acc) {
          index++;
        }
        acc[key + '_' + index] = value;
      } else {
        acc[key] = value;
      }

      return acc;
    }, {});
    selectedNode && setInitialValues({ ...initialValues, label: selectedNode?.data?.label, descript: selectedNode?.data?.descript });
  }, [parameters, selectedNode]);

  const canAddEdge = useCallback(
    (source, target) => {
      const edgeCount = edges.filter((edge) => edge.source === source && edge.target === target).length;
      return edgeCount < 1;
    },
    [edges],
  );

  const onConnect = useCallback(
    (params) => {
      if (canAddEdge(params.source, params.target)) {
        if (params.source != params.target) {
          if (edges.length === 0) {
            setEdges((eds) => addEdge(params, eds));
          }
          const addSecondLine = () => {
            return edges.find((item) => {
              if (item.id === `reactflow__edge-${params.target}${params.sourceHandle}-${params.source}${params.targetHandle}`) {
                return true;
              } else {
                return false;
              }
            });
          };

          if (addSecondLine()) {
            setEdges((eds) => addEdge({ ...params }, eds));
          } else {
            setEdges((eds) => addEdge(params, eds));
          }
        }
      }
    },
    [setEdges, canAddEdge],
  );

  const nodeRadius = 50;
  const debouncedAddNewNode = debounce(() => {
    const currentDate = new Date();
    const formattedDate = moment(currentDate).format('DD-MM-YYYY HH:mm:ss');
    const maxId = nodes.length === 0 ? 0 : Math.max(...nodes.map((n) => parseInt(n.id, 10)));
    const newId = (maxId + 1).toString();
    let centerX = (containerDimensions.width - 418) / 2;
    const centerY = containerDimensions.height / 2;
    let collision = true;
    while (collision) {
      collision = false;
      for (const node of nodes) {
        const distanceX = Math.abs(centerX - node.position.x);
        const distanceY = Math.abs(centerY - node.position.y);
        const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);

        if (distance < nodeRadius * 2) {
          collision = true;
          centerX += nodeRadius * 2;
          break;
        }
      }
    }

    const newNode = {
      type: 'custom',
      className: 'default-color contourSize colorSaturation circleSize node-selected-element',
      id: newId,
      position: { x: centerX, y: centerY },
      data: { id: newId, label: '', descript: '', createData: formattedDate },
    };
    console.log(centerX, centerY);
    setNodes((ns) => [...ns, newNode]);
    setSelectedNode(newNode);
    setInitialValues({});
  }, 50);

  const handleNodeClick = (event, element) => {
    if (!isEdgeControlsOpened) {
      let isEmpty = true;
      for (const key in initialValues) {
        isEmpty = false;
        key;
        break;
      }
      const isObjectNotEmpty = () => {
        if (!initialValues.hasOwnProperty('descript')) {
          return true;
        }
        for (const key in initialValues) {
          if (key !== 'descript' && (initialValues[key] === '' || initialValues[key] === '0')) {
            return false;
          }
        }
        return true;
      };

      if (isEmpty || isObjectNotEmpty() || !selectedNode) {
        setSelectedNode(element);
        const updatedNodes = nodes.map((item) => {
          if (item.className.includes('node-selected-element') && !element.className.includes('node-selected-element')) {
            const classes = item.className.split(' ');
            const updatedClasses = classes.filter((className) => className !== 'node-selected-element');
            item.className = updatedClasses.join(' ');
          } else if (item.data.id === element.data.id) {
            item.className += ' node-selected-element';
          }
          return item;
        });
        setNodes(updatedNodes);
      }
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      const { offsetWidth, offsetHeight } = containerRef.current;
      setContainerDimensions({ width: offsetWidth, height: offsetHeight });
    }
  }, []);

  useEffect(() => {
    const stringifiedNodes = JSON.stringify(nodes);
    if (initialStorage !== stringifiedNodes) {
      sessionStorage.setItem('initialNodes', stringifiedNodes);
    }
  }, [nodes]);

  useEffect(() => {
    const stringifiedEdges = JSON.stringify(edges);
    if (initialStorageEdges !== stringifiedEdges) {
      sessionStorage.setItem('initialEdges', stringifiedEdges);
    }
    if (edges.length > 0) {
      edges.map((item) => {
        if (item.id && item.style.stroke === 'black') {
          setTimeout(() => {
            const element = document.getElementById(item.id);
            if (element) {
              const clickedEdge = element.closest('.react-flow__edge');
              const clickEvent = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
              });
              clickedEdge.dispatchEvent(clickEvent);
            }
          }, 100);
        }
      });
    }
  }, [edges, nodes]);

  useEffect(() => {
    let isPossibleCreateConnection = true;

    const isObjectNotEmpty = () => {
      for (const node of nodes) {
        for (const key in initialValues) {
          if (key !== 'descript' && (!node.data || !(key in node.data) || node.data[key] === '' || node.data[key] === '0')) {
            isPossibleCreateConnection = false;
            break;
          }
        }
        if (!isPossibleCreateConnection) {
          break;
        }
      }
    };

    if (nodes.length < 2 || whichNodeIsEdited) {
      isPossibleCreateConnection = false;
    } else {
      isObjectNotEmpty();
    }

    const styleContent = isPossibleCreateConnection ? 'width: 80%; height: 80%;' : 'width: calc(100% + 30px); height: calc(100% + 30px);';
    const styleHTML = `<style>.custom-node::before { content: ""; ${styleContent}</style>`;
    document.head.insertAdjacentHTML('beforeend', styleHTML);
  }, [nodes, whichNodeIsEdited]);
  const translate = useTranslation(formatMessage, study?.translations);
  useEffect(() => {
    const handleGlobalClick = async (e) => {
      if (e.target.className === 'react-flow__pane') {
        let isMissingElement = false;
        setWhichEdgeControlsIsOpen(null);
        setIsEdgeSliderOpen(false);
        await nodes.map((item) => {
          if (item.id === selectedNode.id) {
            if (item.data.label === '') {
              isMissingElement = true;

                  // setIsMissingParameter(true);
                  // setWhichEdgeControlsIsOpen(null);
              
            }
            parameters.map((item) => {
              if (!selectedNode.data.hasOwnProperty(item.graphicChange) || selectedNode.data.label === '') {
                isMissingElement = true;
                    // setIsMissingParameter(true);
                    // setWhichEdgeControlsIsOpen(null);
              } else {
                isMissingElement = false;
                setSelectedNode(null);
              }
            });
          }
        });

        if (!isMissingElement) {
          setSelectedNode(null);
          const updatedNodes = nodes.map((item) => {
            if (item.className.includes('node-selected-element')) {
              const classes = item.className.split(' ');
              const updatedClasses = classes.filter((className) => className !== 'node-selected-element');
              item.className = updatedClasses.join(' ');
            }
            return item;
          });
          setNodes(updatedNodes);
        }
      }
    };
    document.addEventListener('click', handleGlobalClick);
    return () => {
      document.removeEventListener('click', handleGlobalClick);
    };
  }, [nodes, selectedNode]);

  // const handleOnWheel = () => {
  //   const edgeControls = document.getElementById('edge-controls');
  //   if (!edgeControls?.classList?.contains('edge-controls-hidden')) {
  //     edgeControls?.classList?.add('edge-controls-hidden');
  //   }
  // };

  const handleDeleteNode = () => {
    const updatedEdges = edges.filter((item) => item.target !== selectedNode?.id && item.source !== selectedNode?.id);
    setEdges(updatedEdges);
    setSelectedNode(null);
    setInitialValues({});
    setIsMissingParameter(false);
  };

  return (
    <div className="page-wrapper">
      <TopBar
        addFileMap={addFileMap}
        setIsMissingParameter={setIsMissingParameter}
        setScreenLastPage={setScreenLastPage}
        study={study}
        sendStudyForm={sendStudyForm}
        showButtons
        setNodes={setNodes}
        nodes={nodes}
        selectedNode={selectedNode}
        setSelectedNode={setSelectedNode}
        initialValues={initialValues}
        edges={edges}
        formValues={formValues}
        setInitialValues={setInitialValues}
        translations={study.translations}
        formatMessage={formatMessage}
      />
      <div className="target">
        <EdgeControls
          isEdgeSliderOpen={isEdgeSliderOpen}
          setIsEdgeSliderOpen={setIsEdgeSliderOpen}
          whichNodeIsEdited={whichNodeIsEdited}
          setWhichNodeIsEdited={setWhichNodeIsEdited}
          whichEdgeControlsIsOpen={whichEdgeControlsIsOpen}
          setWhichEdgeControlsIsOpen={setWhichEdgeControlsIsOpen}
          isMissingParameter={isMissingParameter}
          setIsMissingParameter={setIsMissingParameter}
          edges={edges}
          setEdges={setEdges}
          isEdgeControlsOpened={isEdgeControlsOpened}
          setIsEdgeControlsOpened={setIsEdgeControlsOpened}
          translate={translate}
        />
        <div className={`${boardTargetClass}`} ref={containerRef}>
          <div id="add-new-target" onClick={debouncedAddNewNode} />
          <ReactFlowProvider>
            <ReactFlow
              // onWheelCapture={() => handleOnWheel()}
              // onMove={() => handleOnWheel()}
              onNodeClick={handleNodeClick}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              defaultEdgeOptions={defaultEdgeOptions}
              connectionLineStyle={connectionLineStyle}
              onNodeDragStop={() => null}
              deleteKeyCode={null}
              onNodesDelete={() => {
                handleDeleteNode();
              }}>
              <Controls />
              <Background gap={12} size={1} />
              <FocusedNode />
              <BoardInfo
                selectedNode={selectedNode}
                whichEdgeControlsIsOpen={whichEdgeControlsIsOpen}
                isMissingParameter={isMissingParameter}
                formValues={formValues}
                nodes={nodes}
                isEdgeControlsOpened={isEdgeControlsOpened}
                translate={translate}
              />
            </ReactFlow>
          </ReactFlowProvider>
        </div>
        <SidebarTarget
          initialValues={initialValues}
          parameters={parameters}
          nodes={nodes}
          setNodes={setNodes}
          setSelectedNode={setSelectedNode}
          selectedNode={selectedNode}
          handleDeleteNode={handleDeleteNode}
          setFormValues={setFormValues}
          translate={translate}
        />
      </div>
    </div>
  );
};

export default BoardTarget;
