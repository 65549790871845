import { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, generatePath } from 'react-router-dom';
import { SVG } from 'Shared/components';
import { ROUTES, SVG_TYPE, USER_ROLES } from 'Shared/enums';

const DashboardSidebarMenu = ({ authorizedUser }: any) => {
  const [isExpand, setIsExpand] = useState(false);
  const navigate = useNavigate();
  const { pathname } = window.location;
  const isUsersPageActive = pathname.includes('users');
  const isTestPageActive = pathname.includes('stud');
  const sidebarRef = useRef(null);
  const { formatMessage } = useIntl();

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) && isExpand) {
        setIsExpand(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isExpand]);

  return (
    <div ref={sidebarRef} className={`dashboard-sidebar-menu ${isExpand ? 'active' : ''}`}>
      <div className={`dashboard-sidebar-menu__logo ${isExpand ? 'active' : ''}`}>
        <div className="logo" onClick={() => setIsExpand(true)}>
          {!isExpand ? <SVG type={SVG_TYPE.SYGNET} /> : <SVG type={SVG_TYPE.GOALMAPP_WHITE} />}
        </div>
        {isExpand && (
          <div className="dashboard-sidebar-menu__close" onClick={() => setIsExpand(false)}>
            <SVG type={SVG_TYPE.CLOSE} />
          </div>
        )}
      </div>
      <div className="dashboard-sidebar-menu__list">
        <div
          className={`dashboard-sidebar-menu__item ${isTestPageActive ? 'dashboard-sidebar-menu__item--active' : ''}`}
          onClick={() => navigate({ pathname: generatePath(ROUTES.STUDIES, { page: '1' }) })}>
          <div className="dashboard-sidebar-menu__item-image">
            <SVG type={SVG_TYPE.TESTS} />
          </div>
          <div className="dashboard-sidebar-menu__item-label">{formatMessage({ id: 'study' })}</div>
        </div>
        {authorizedUser?.role === USER_ROLES.ADMIN ? (
          <div
            className={`dashboard-sidebar-menu__item ${isUsersPageActive ? 'dashboard-sidebar-menu__item--active' : ''}`}
            onClick={() => navigate({ pathname: generatePath(ROUTES.USERS, { page: '1' }) })}>
            <div className="dashboard-sidebar-menu__item-image">
              <SVG type={SVG_TYPE.ADMINISTRATORS} />
            </div>
            <div className="dashboard-sidebar-menu__item-label">{formatMessage({ id: 'users' })}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DashboardSidebarMenu;
