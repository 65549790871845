import { useIntl } from 'react-intl';
import { TopBar, SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import { useTranslation } from 'Shared/utils';

const GetLost = ({ translations }: any) => {
  const { formatMessage } = useIntl();
  const translate = useTranslation(formatMessage, translations);
  return (
    <div className="page-wrapper">
      <TopBar formatMessage={formatMessage} translations={translations} />
      <div className="get-lost">
        <div className="get-lost__content">
          <SVG type={SVG_TYPE.GET_LOST} />
          <div className="get-lost__header">{translate('you_get_lost')}</div>
          <div className="get-lost__paragraph">{translate('this_study_is_inactive')}</div>
        </div>
      </div>
    </div>
  );
};

export default GetLost;
