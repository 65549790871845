import { connect } from 'react-redux';
import HomePage from './Main';
import { bindActionCreators } from 'redux';
import { getStudyByLink, sendStudyForm, getUserIsActive, addFileMap } from 'Store/Actions/Study';
import { getStudyByLink as getStudyByLinkSelector } from 'Store/Selectors/StudySelector';
import { authorizedUser as authorizedUserSelector } from 'Store/Selectors/AuthSelector';

type ConnectedP = {
  studyByLink: any;
  authorizedUser: any;
};

type DispatchedP = {
  actions: {
    getStudyByLink: (link, id) => any;
    sendStudyForm: (userUuis) => any;
    getUserIsActive: (userUuid) => any;
    addFileMap: (filemap) => any;
  };
};
const mapStateToProps = (state) => ({
  studyByLink: getStudyByLinkSelector(state),
  authorizedUser: authorizedUserSelector(state),
});


const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      addFileMap: addFileMap,
      getStudyByLink: getStudyByLink,
      sendStudyForm: sendStudyForm,
      getUserIsActive: getUserIsActive,
    },
    dispatch,
  ),
});
export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
