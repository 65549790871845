enum ROUTES {
  HOME_PAGE = '/:uuid',
  LOGIN = '/login',
  FORGOTTEN_PASSWORD = '/forgotten-password',
  CHANGE_PASSWORD = '/change-password',
  DASHBOARD = '/dashboard',
  STUDIES = '/dashboard/studies/:page',
  ADD_STUDY = '/dashboard/add-study',
  EDIT_STUDY = '/dashboard/edit-study/:uuid',
  ANSWERS_STUDY = '/dashboard/answers-study/:study/:page',
  USERS = '/dashboard/users/:page',
  ACTIVATE_USER_PAGE = '/activate',
  ACTIVATE_EDITOR_PAGE = '/activate/editor',
  REGISTER = '/register',
}

export default ROUTES;
