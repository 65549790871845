import * as UserModule from '../../../Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import { translatedMessage } from 'Shared/utils';
const init = () => ({
  type: User.SUSPEND_USER_INIT,
});

const success = (data) => ({
  type: User.SUSPEND_USER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.SUSPEND_USER_FAILURE,
});

const suspendUser = (uuid, action) => async (dispatch) => {
  dispatch(init());
  const isSuspend = action === 'suspend';
  const id = toast.loading(isSuspend ? translatedMessage('toast.pending_suspend_user') : translatedMessage('toast.pending_restore_user'));
  try {
    const { data } = await UserModule.Connector.suspendUser(uuid);
    toast.update(id, {
      render: isSuspend ? translatedMessage('toast.suspend_user') : translatedMessage('toast.restore_user'),
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    dispatch(success(data.user));
  } catch (err: any) {
    dispatch(failure());
    toast.update(id, {
      render: isSuspend ? translatedMessage('toast.suspend_user_error') : translatedMessage('toast.restore_user_error'),
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
    });
  }
};

export default suspendUser;
