import * as Types from 'Shared/models/UserModel';
import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';
import { AuthAction } from '../Actions/Models/AuthActionModel';

export type State = Types.UserStore;

export const initialState: State = {
  users: null,
};

export function userReducer(state: State = initialState, action: AuthAction) {
  switch (action.type) {
    case ActionTypes.User.GET_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload.data,
      };
    }
    case ActionTypes.User.EDIT_USER_SUCCESS: {
      const editUserUuid = action.payload.data.uuid;
      const updatedUsers = state.users.data.map((user) => {
        if (user.uuid === editUserUuid) {
          return {
            ...action.payload.data,
          };
        }
        return user;
      });
      return {
        ...state,
        users: { meta: state.users.meta, data: updatedUsers },
      };
    }
    case ActionTypes.User.SUSPEND_USER_SUCCESS: {
      const editUserUuid = action.payload.data.uuid;
      const updatedUsers = state.users.data.map((user) => {
        if (user.uuid === editUserUuid) {
          return {
            ...action.payload.data,
          };
        }
        return user;
      });
      return {
        ...state,
        users: { meta: state.users.meta, data: updatedUsers },
      };
    }

    case ActionTypes.User.REGISTER_USER_SUCCESS: {
      return {
        ...state,
      };
    }
    case ActionTypes.User.REGISTER_USER_FAILURE: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}
