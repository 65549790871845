import { connect } from 'react-redux';
import RegisterPage from './Main';
import { bindActionCreators } from 'redux';
import { getAuthorizedUser } from 'Store/Actions/Auth';
import { registerUser } from 'Store/Actions/User';

type ConnectedP = {
  changelogs: Array<any>;
};

type DispatchedP = {
  actions: {
    register: (any) => any;
    getAuthorizedUser: () => any;
  };
};
const mapStateToProps = (state) => ({
  changelogs: state.changelogs,
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      register: registerUser,
      getAuthorizedUser: getAuthorizedUser,
    },
    dispatch,
  ),
});
export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
