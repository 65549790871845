import * as StudyModule from '../../../Modules/StudyModule';
import { Study } from '../EnumTypes/ActionEnumTypes';

const success = (files) => ({
  type: Study.GET_STUDY_BY_LINK_SUCCESS,
  payload: {
    data: files,
  },
});

const failure = () => ({
  type: Study.GET_STUDY_BY_LINK_FAILURE,
});

const getStudyByLink = (link, id) => async (dispatch) => {
  try {
    const { data } = await StudyModule.Connector.getStudyByLink({ link, id });
    dispatch(success(data));
    return data;
  } catch (err: any) {
    dispatch(failure());
    return err.message;
  }
};

export default getStudyByLink;
