import * as StudyModule from '../../../Modules/StudyModule';
import { Study } from '../EnumTypes/ActionEnumTypes';
const init = () => ({
  type: Study.ADD_STUDY_FILE_INIT,
});

const success = (files) => ({
  type: Study.ADD_STUDY_FILE_SUCCESS,
  payload: {
    data: files,
  },
});

const failure = () => ({
  type: Study.ADD_STUDY_FILE_FAILURE,
});

const addFileMap = (dataFile) => async (dispatch) => {
  dispatch(init());
  const response = await fetch(dataFile);
  const blob = await response.blob();
  const file = new FormData();
  file.append('file', blob);
  try {
    const response = await StudyModule.Connector.addFileMap(file);
    dispatch(success(response.data));
    return response.data.mapFilesUuid;
  } catch (err: any) {
    dispatch(failure());
    return false;
  }
};

export default addFileMap;
