import { Modal } from 'Shared/components';
import { useState } from 'react';
import { Input, Button, PasswordValidation, PasswordStrengthChecker } from 'Shared/components';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';

type ChangePasswordModalType = {
  isModalOpen: boolean;
  isModalClose: () => void;
  changeCurrentAdminPassword: (currentPassword, password) => any;
};

const ChangePasswordModal = ({ isModalOpen, isModalClose, changeCurrentAdminPassword }: ChangePasswordModalType) => {
  const [showValidation, setShowValidation] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [password, setPassword] = useState('');
  const { formatMessage } = useIntl();

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(formatMessage({ id: 'validation.required' })),
    confirmPassword: Yup.string()
      .required(formatMessage({ id: 'validation.required' }))
      .min(8, formatMessage({ id: 'validation.password_length' }))
      .test('passwords-match', formatMessage({ id: 'validation.password_mismatch' }), function (value) {
        return this.parent.password === value;
      }),
    password: Yup.string()
      .required(formatMessage({ id: 'validation.required' }))
      .min(8, formatMessage({ id: 'validation.password_length' }))
      .test('Capital Letter', '', function (value) {
        return /[A-Z]/.test(value);
      })
      .test('Lowercase Letter', '', function (value) {
        return /[a-z]/.test(value);
      })
      .test('Number', '', function (value) {
        return /[0-9]/.test(value);
      })
      .test('cannot-start-with-space', formatMessage({ id: 'validation.cannot_start_with_space' }), function (value) {
        return !value?.startsWith(' ');
      }),
  });

  const handleBlur = async () => {
    isPasswordValid && setShowValidation(false);
    try {
      await Yup.string()
        .required(formatMessage({ id: 'validation.required' }))
        .test('Capital Letter', formatMessage({ id: 'validation.capital_letter' }), function (value) {
          return /[A-Z]/.test(value);
        })
        .test('Lowercase Letter', formatMessage({ id: 'validation.lower_case' }), function (value) {
          return /[a-z]/.test(value);
        })
        .test('Number', formatMessage({ id: 'validation.password_number' }), function (value) {
          return /[0-9]/.test(value);
        })
        .test('cannot-start-with-space', formatMessage({ id: 'validation.cannot_start_with_space' }), function (value) {
          return !value?.startsWith(' ');
        })
        .validate(password, { abortEarly: false });
      setShowValidation(false);
    } catch (validationError) {
      setShowValidation(true);
    }
  };

  const handlePasswordModal = async (values, { resetForm, setFieldError }) => {
    const response = await changeCurrentAdminPassword(values.currentPassword, values.password);
    if (response === true) {
      isModalClose();
      resetForm();
    } else if (response === 'Bad Password') {
      setFieldError('currentPassword', ' ');
    }
  };

  return (
    <Modal
      className="change-password-modal"
      isOpen={isModalOpen}
      onClose={() => {
        isModalClose();
      }}>
      <div className="modal-heading">{formatMessage({ id: 'password_change' })}</div>
      <div className="change-password-modal__form">
        <Formik
          validationSchema={validationSchema}
          initialValues={{ currentPassword: '', password: '', confirmPassword: '' }}
          validateOnBlur={false}
          enableReinitialize
          onSubmit={async (values, props) => handlePasswordModal(values, props)}>
          {({ handleSubmit, errors, values, resetForm }) => (
            <form onSubmit={handleSubmit}>
              <Field
                label={formatMessage({ id: 'previous_password' })}
                name="currentPassword"
                placeholder=""
                required
                component={Input}
                treshold={0}
                type="password"
                maxLength={32}
                onChange={(e) => setPassword(e)}
                onBlur={() => {
                  handleBlur();
                }}
                errors={errors}
              />
              <Field
                label={formatMessage({ id: 'new_password' })}
                name="password"
                placeholder=""
                required
                component={Input}
                treshold={0}
                type="password"
                maxLength={32}
                inputOnFocus={() => setShowValidation(true)}
                onChange={(e) => setPassword(e)}
                onBlur={() => {
                  handleBlur();
                }}
                errors={errors}
              />
              <PasswordStrengthChecker isHidden={!showValidation} password={values.password} />
              <PasswordValidation
                setIsValid={(value: boolean) => setIsPasswordValid(value)}
                className={` ${showValidation ? 'validation-item__wrapper--show' : ''}`}
                password={values.password}
              />
              <Field
                label={formatMessage({ id: 'confirm_new_password' })}
                name="confirmPassword"
                placeholder=""
                required
                component={Input}
                treshold={0}
                type="password"
                maxLength={32}
                errors={errors}
              />
              <div className="change-password-modal__buttons">
                <Button
                  label={formatMessage({ id: 'cancel' })}
                  stroke
                  onClick={() => {
                    resetForm();
                    isModalClose();
                  }}
                />
                <Button label={formatMessage({ id: 'save' })} onClick={() => handleSubmit()} />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
