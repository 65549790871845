import { connect } from 'react-redux';
import ChangePassword from './Main';
import { bindActionCreators } from 'redux';
import { checkToken, recoverPassword, sendPasswordResetLink } from 'Store/Actions/Auth';


type ConnectedP = {
  changelogs: Array<any>;
};

type DispatchedP = {
  actions: {
    checkToken: (token) => any;
    recoverPassword: (password, token) => any;
    sendPasswordResetLink: (email) => any;

  };
};
const mapStateToProps = (state) => ({
  changelogs: state.changelogs,
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      checkToken: checkToken,
      sendPasswordResetLink: sendPasswordResetLink,
      recoverPassword: recoverPassword,
    },
    dispatch,
  ),
});
export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
