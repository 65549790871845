import { useState, useEffect } from 'react';
import { SVG } from 'Shared/components';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { useLocation } from 'react-router-dom';
import { useNavigate, generatePath } from 'react-router-dom';
import { useIntl } from 'react-intl';

type MobileMenuType = {
  handleLogoutUser: any;
  handleOpenModal: any;
};

const MobileMenu = ({ handleLogoutUser, handleOpenModal }: MobileMenuType) => {
  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  useEffect(() => {
    setIsMenuMobileOpen(false);
  }, [pathname, handleOpenModal]);

  useEffect(() => {
    if (isMenuMobileOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuMobileOpen]);

  const isUsersPageActive = pathname.includes('users');
  const isTestPageActive = pathname.includes('stud');

  return (
    <>
      <div className={`dashboard-top-bar ${isMenuMobileOpen ? 'dashboard-top-bar--mobile-active' : ''}`}>
        <div className="dashboard-top-bar__logo">{<SVG type={SVG_TYPE.GOALMAPP_WITHOUT_PADDING} />}</div>
        <div className="dashboard-top-bar-menu__wrapper">
          <div className="mobile-menu__icon" onClick={() => setIsMenuMobileOpen(!isMenuMobileOpen)}>
            {isMenuMobileOpen ? <SVG type={SVG_TYPE.CLOSE} /> : <SVG type={SVG_TYPE.HAMBURGER} />}
          </div>
        </div>
      </div>
      <div className="mobile-menu__wrapper">
        <div className={`mobile-menu__content ${isMenuMobileOpen ? 'mobile-menu__content--active' : ''}`}>
          <div className="mobile-menu__list">
            <div
              className={`mobile-menu__item ${isTestPageActive ? 'mobile-menu__item--active' : ''}`}
              onClick={() => navigate({ pathname: generatePath(ROUTES.STUDIES, { page: '1' }) })}>
              {formatMessage({ id: 'study' })}
            </div>
            <div
              className={`mobile-menu__item ${isUsersPageActive ? 'mobile-menu__item--active' : ''}`}
              onClick={() => navigate({ pathname: generatePath(ROUTES.USERS, { page: '1' }) })}>
              {formatMessage({ id: 'users' })}
            </div>
            <div className="mobile-menu__item" onClick={() => handleOpenModal()}>
              {formatMessage({ id: 'change_password' })}
            </div>
            <div className="mobile-menu__logout" onClick={() => handleLogoutUser()}>
              {formatMessage({ id: 'logout' })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
