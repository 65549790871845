import React from 'react';
import { SVG_TYPE } from 'Shared/enums';
import SVG from '../SVG';

export type SortStateType = 'desc' | 'asc' | null;

interface Props {
  state: SortStateType;
  className?: string;
  onChange: (state: SortStateType) => void;
  reverse?: boolean;
}

const Sort: React.FC<Props> = ({ state, className = '', onChange, reverse }) => {
  const handleClick = () => {
    if (state === 'desc') {
      onChange('asc');
    } else {
      onChange('desc');
    }
  };
  return (
    <span className={`sort ${className}`} onClick={() => handleClick()}>
      <SVG
        className={`sort__arrow 
        ${state === 'desc' && !reverse ? 'sort__arrow--active' : ''} 
        ${reverse && state === 'asc' ? 'sort__arrow--active' : ''}`}
        type={SVG_TYPE.ARROW_DOWN}
      />
      <SVG
        className={`sort__arrow sort__arrow--up 
        ${state === 'asc' && !reverse ? 'sort__arrow--active' : ''}
        ${reverse && state === 'desc' ? 'sort__arrow--active' : ''}`}
        type={SVG_TYPE.ARROW_DOWN}
      />
    </span>
  );
};

export default Sort;
