import * as AuthModule from 'Modules/AuthModule';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';

export const success = (authData) => ({
  type: Auth.CHECK_TOKEN_SUCCESS,
  payload: {
    authData,
  },
});

const failure = () => ({
  type: Auth.CHECK_TOKEN_FAILURE,
});

const checkToken = (token: string) => async (dispatch) => {
  try {
    const response = await AuthModule.Connector.checkToken(token);
    dispatch(success(response.data));
    return true;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default checkToken;
