import Select from 'react-select';

type SelectEdgeOptionsType = {
  placeholder?: string;
  options?: Array<{ value; label }>;
  onChange?: any;
  value?: any;
  onClick?: any;
  noOptionsMessage?: any;
  isError?: any;
  setWhichEdgeControlsIsOpen?: any;
  name?: any;
};

const SelectEdgeOptions = ({
  placeholder,
  options,
  onChange,
  value,
  noOptionsMessage,
  isError,
  setWhichEdgeControlsIsOpen,
  name,
}: SelectEdgeOptionsType) => {
  const language = sessionStorage.getItem('language');
  const isEnLanguage = language === 'EN';
  return (
    <Select
      onMenuOpen={() => setWhichEdgeControlsIsOpen(name)}
      // onMenuClose={() => setWhichEdgeControlsIsOpen(null)}
      value={value}
      onChange={(e) => onChange(e)}
      placeholder={placeholder}
      name={'test'}
      className={`select-edge ${isError ? 'select-edge__error' : ''}`}
      isClearable={false}
      classNamePrefix={'select'}
      options={options}
      noOptionsMessage={() => `${noOptionsMessage ? `${isEnLanguage ? 'No options' : 'Brak wyników'}` : ' '}`}
      filterOption={(option: any, input) => option.data.label.toLowerCase().includes(input.toLowerCase())}
    />
  );
};

export default SelectEdgeOptions;
