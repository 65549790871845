import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

type LoginContentType = {
  children?: any;
  heading: string;
  goBack?: boolean;
  buttonLabel?: string;
  onClick?: any;
  descript?: any;
  goBackFunc?: any;
  activate?: any;
  acitaveLinkExpired?: any;
};
const LoginContent = ({ children, heading, goBack, descript, goBackFunc, activate, acitaveLinkExpired }: LoginContentType) => {
  return (
    <div className="page-wrapper page-wrapper--width-center">
      <div id="loginContent" className="login-content__wrapper">
        <div className="login-content--goalmapp">
          <SVG type={SVG_TYPE.GOALMAPP} />
        </div>
        <div className="login-content">
          <div className="login-content__logo-wrapper">
            <SVG type={SVG_TYPE.LOGO} />
          </div>
          <div className="login-content-header">
            {goBack && (
              <div className="login-content-header__back" onClick={() => goBackFunc()}>
                <SVG type={SVG_TYPE.ARROW_BACK} />
              </div>
            )}
            <span>{heading}</span>
          </div>
          {descript && (
            <div
              className={`login-content__descript ${activate ? 'login-content__descript--activate' : ''} ${
                acitaveLinkExpired ? 'login-content__descript--activate-link-expired' : ''
              }`}>
              {descript}
            </div>
          )}
          <div className="login-content__children">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default LoginContent;
