import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';

export function getMapReport(mapUuid): AxiosPromise {
  return API.get(`reports?mapUuid=${mapUuid}`);
}

export function getGroupReport(link): AxiosPromise {
  return API.get(`reports/all?link=${link}`);
}
