import * as Types from 'Shared/models/ReportModel';
import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';
import { AuthAction } from '../Actions/Models/AuthActionModel';

export type State = Types.ReportStore;

export const initialState: State = {
  mapReport: null,
};

export function reportReducer(state: State = initialState, action: AuthAction) {
  switch (action.type) {
    case ActionTypes.Report.GET_MAP_REPORT_SUCCESS: {
      return {
        ...state,
        mapReport: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
}
