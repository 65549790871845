import { Modal, Button } from 'Shared/components';

type RemoveTargetModalType = {
  handleRemoveTarget: any;
  isOpenModal: any;
  close: any;
  translate: any;
};

const RemoveTargetModal = ({ handleRemoveTarget, close, isOpenModal, translate }: RemoveTargetModalType) => {
  return (
    <Modal
      className="top-bar__end-map-modal"
      isOpen={isOpenModal}
      onClose={() => {
        close();
      }}>
      <div className="modal-heading">{translate('goal_delete')}</div>
      <div className="modal-description">{translate('goal_delete_description')}</div>
      <div className="top-bar__end-map-modal__buttons">
        <Button label={translate('cancel')} stroke onClick={() => close()} />
        <Button label={translate('delete')} onClick={() => handleRemoveTarget()} />
      </div>
    </Modal>
  );
};

export default RemoveTargetModal;
