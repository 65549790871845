import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export function addStudy(data): AxiosPromise {
  return API.post('studies', { ...data });
}

export function getStudies({ page, order, sortBy }): AxiosPromise {
  return API.get('studies', { params: { page, order, sortBy } });
}

export function addStudyFiles(formData, data): AxiosPromise {
  const queryString = qs.stringify(data);
  return API.post(`studies/files?${queryString}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function deleteStudy(data): AxiosPromise {
  return API.delete('studies', { data });
}

export function getNewStudyUuid(): AxiosPromise {
  return API.get('studies/lead');
}

export function getStudyFiles(uuid): AxiosPromise {
  return API.get(`studies/files?testUuid=${uuid}`);
}

export function deleteStudyFile(uuid): AxiosPromise {
  return API.delete(`studies/files?fileUuid=${uuid}`);
}

export function getStudyByLink(data): AxiosPromise {
  const queryString = qs.stringify(data);
  return API.get(`studies/link?${queryString}`);
}

export function sendStudyForm(userUuid): AxiosPromise {
  return API.post(`studies/link/data`, userUuid);
}

export function getStudyAnswerList(data): AxiosPromise {
  const queryString = qs.stringify(data);
  return API.get(`studies/map?${queryString}`);
}

export function getStudyForEdit(uuid): AxiosPromise {
  return API.get(`studies/edit?testUuid=${uuid}`);
}

export function editStudyForm(data): AxiosPromise {
  return API.put(`studies/`, data);
}

export function getUserIsActive(uuid): AxiosPromise {
  return API.get(`users/isActive?userUuid=${uuid}`);
}

export function deleteMap(data): AxiosPromise {
  return API.delete('studies/map', { data });
}

export function addFileMap(data): AxiosPromise {
  return API.post(`studies/map/files`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

// export function getStudiesMapFile(uuid): AxiosPromise {
//   return API.get(`studies/map/files?mapUuid=${uuid}`,{
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     });
// }

export function getStudiesMapFile(uuid): AxiosPromise {
  return API.get(`studies/map/files?mapUuid=${uuid}`);
}
