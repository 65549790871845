import { connect } from 'react-redux';
import AnswersTest from './Main';
import { bindActionCreators } from 'redux';
import { checkToken } from 'Store/Actions/Auth';
import { getStudyAnswerList, deleteMap, getStudiesMapFile } from 'Store/Actions/Study';
import { getMapReport, getGroupReport } from 'Store/Actions/Report';
import { getStudyAnswersList } from 'Store/Selectors/StudySelector';
import { getMapReport as getMapReportSelector } from 'Store/Selectors/ReportSelector';


type ConnectedP = {
  changelogs: Array<any>;
  studyAnswersList: any;
  reportMap: any;
};

type DispatchedP = {
  actions: {
    checkToken: (token) => any;
    getStudyAnswerList: (data) => any;
    getMapReport: (mapUuid) => any;
    deleteMap: (data) => any;
    getGroupReport: (link) => any;
    getStudiesMapFile: (link) => any;
  };
};
const mapStateToProps = (state) => ({
  changelogs: state.changelogs,
  studyAnswersList: getStudyAnswersList(state),
  reportMap: getMapReportSelector(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getStudiesMapFile: getStudiesMapFile,
      checkToken: checkToken,
      getStudyAnswerList: getStudyAnswerList,
      getMapReport: getMapReport,
      deleteMap: deleteMap,
      getGroupReport: getGroupReport,
    },
    dispatch,
  ),
});
export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(AnswersTest);
