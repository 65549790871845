import { useIntl } from 'react-intl';
import { Modal, Button } from 'Shared/components';
import { USER_ROLES } from 'Shared/enums';

type SuspendUserModalType = {
  suspendUser: (uuid, action) => void;
  onClose: () => void;
  userUuid: boolean;
  action: string;
  role: USER_ROLES;
};

const SuspendUserModal = ({ suspendUser, userUuid, onClose, action, role }: SuspendUserModalType) => {
  const isSuspend = action === 'suspend';
  const isAdmin = role === USER_ROLES.ADMIN;
  const { formatMessage } = useIntl();

  const handleSuspendUser = async (uuid, action) => {
    await suspendUser(uuid, action);
    onClose();
  };

  return (
    <Modal className="add-administrator-modal" isOpen={userUuid} onClose={() => onClose()}>
      <div className="modal-heading">
        {isSuspend
          ? isAdmin
            ? formatMessage({ id: 'suspend_admin' })
            : formatMessage({ id: 'suspend_editor' })
          : isAdmin
          ? formatMessage({ id: 'restore_admin' })
          : formatMessage({ id: 'restore_editor' })}
      </div>
      <div className="suspend-user-modal__descript">
        {isSuspend
          ? isAdmin
            ? formatMessage({ id: 'do_you_really_want_suspend_admin' })
            : formatMessage({ id: 'do_you_really_want_suspend_editor' })
          : isAdmin
          ? formatMessage({ id: 'do_you_really_want_restore_admin' })
          : formatMessage({ id: 'do_you_really_want_restore_editor' })}{' '}
      </div>
      <div className="suspend-user-modal__buttons">
        <Button label={formatMessage({ id: 'cancel' })} stroke onClick={() => onClose()} />
        <Button label={formatMessage({ id: 'confirm' })} onClick={() => handleSuspendUser(userUuid, action)} />
      </div>
    </Modal>
  );
};

export default SuspendUserModal;
