import { connect } from 'react-redux';
import ActivateUserPage from './Main';
import { bindActionCreators } from 'redux';
import { checkToken, setPassword } from 'Store/Actions/Auth';


type ConnectedP = {
  changelogs: Array<any>;
};

type DispatchedP = {
  actions: {
    checkToken: (token) => any;
    setPassword: (password,token) => any;
  };
};
const mapStateToProps = (state) => ({
  changelogs: state.changelogs,
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      checkToken: checkToken,
      setPassword: setPassword,
    },
    dispatch,
  ),
});
export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(ActivateUserPage);
