import { connect } from 'react-redux';
import TestsPage from './Main';
import { bindActionCreators } from 'redux';
import { getStudies, deleteStudy } from 'Store/Actions/Study';
import { getStudies as getStudiesSelector } from 'Store/Selectors/StudySelector';
import { getCustomTranslations, storeCustomTranslations } from 'Store/Actions/CustomTranslations';
import { getCustomTranslationsSelector } from 'Store/Selectors/CustomTranslationsSelector';

type ConnectedP = {
  tests: any;
  translations: any;
};

type DispatchedP = {
  actions: {
    getStudies: (data) => any;
    deleteStudy: (data) => any;
    storeCustomTranslations: (data) => any;
    getCustomTranslations: (uuid) => any;
  };
};
const mapStateToProps = (state) => ({
  tests: getStudiesSelector(state),
  translations: getCustomTranslationsSelector(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getStudies: getStudies,
      deleteStudy: deleteStudy,
      storeCustomTranslations: storeCustomTranslations,
      getCustomTranslations: getCustomTranslations,
    },
    dispatch,
  ),
});
export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(TestsPage);
