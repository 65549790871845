import { connect } from 'react-redux';
import Dashboard from './Main';
import { bindActionCreators } from 'redux';
import { Actions as AuthActions } from 'Modules/AuthModule';
import { changeCurrentAdminPassword } from 'Store/Actions/Auth';
import { authorizedUser as authorizedUserSelector } from 'Store/Selectors/AuthSelector';

type ConnectedP = {
  changelogs: Array<any>;
  authorizedUser: any;
};

type DispatchedP = {
  actions: {
    logout: () => any;
    getAuthorizedUser: () => any;
    changeCurrentAdminPassword: (currentPassword, password) => any;
  };
};
const mapStateToProps = (state) => ({
  changelogs: state.changelogs,
  authorizedUser: authorizedUserSelector(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      logout: AuthActions.logout,
      getAuthorizedUser: AuthActions.getAuthorizedUser,
      changeCurrentAdminPassword: changeCurrentAdminPassword,
    },
    dispatch,
  ),
});
export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
