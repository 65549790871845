import { useState, useEffect } from 'react';
import { LoginContent, Input, Button, PasswordValidation, PasswordStrengthChecker } from 'Shared/components';
import { Field, Formik } from 'formik';
import { ROUTES } from 'Shared/enums';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { MainType } from './Container';
import { useIntl } from 'react-intl';

localStorage.getItem('resendMail');

const ActivateUserPage = (props: MainType) => {
  const { actions } = props;
  const { checkToken, setPassword } = actions;
  const [showValidation, setShowValidation] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [isTokenActive, setIsTokenActive] = useState(false);
  const [isPassword, isSetPassword] = useState('');
  const navigate = useNavigate();
  const urlString = window.location.href;
  const url = new URL(urlString);
  const token = url.searchParams.get('token');
  const { formatMessage } = useIntl();

  const validationSchema = Yup.object().shape({
    confirmPassword: Yup.string()
      .min(8, formatMessage({ id: 'validation.password_length' }))
      .required(formatMessage({ id: 'validation.required' }))
      .test('passwords-match', formatMessage({ id: 'validation.password_mismatch' }), function (value) {
        return this.parent.password === value;
      }),
    password: Yup.string()
      .min(8, formatMessage({ id: 'validation.password_length' }))
      .required(formatMessage({ id: 'validation.required' }))
      .test('Capital Letter', '', function (value) {
        return /[A-Z]/.test(value);
      })
      .test('Lowercase Letter', '', function (value) {
        return /[a-z]/.test(value);
      })
      .test('Number', '', function (value) {
        return /[0-9]/.test(value);
      })
      .test('cannot-start-with-space', formatMessage({ id: 'validation.cannot_start_with_space' }), function (value) {
        return !value?.startsWith(' ');
      }),
  });

  useEffect(() => {
    checkToken(token)
      .then((response) => {
        response && setIsTokenActive(true);
      })
      .catch((error) => {
        error;
      });
  }, [token]);

  const handleActivateUser = (values) => {
    setPassword(values.password, token)
      .then((response) => {
        response && setIsSend(true);
      })
      .catch((error) => {
        error;
      });
  };
  const handleBlur = async () => {
    isPasswordValid && setShowValidation(false);
    try {
      await Yup.string()
        .min(8, formatMessage({ id: 'validation.password_length' }))
        .required(formatMessage({ id: 'validation.required' }))
        .test('Capital Letter', formatMessage({ id: 'validation.capital_letter' }), function (value) {
          return /[A-Z]/.test(value);
        })
        .test('Lowercase Letter', formatMessage({ id: 'validation.lower_case' }), function (value) {
          return /[a-z]/.test(value);
        })
        .test('Number', formatMessage({ id: 'validation.password_number' }), function (value) {
          return /[0-9]/.test(value);
        })
        .test('cannot-start-with-space', formatMessage({ id: 'validation.cannot_start_with_space' }), function (value) {
          return !value?.startsWith(' ');
        })
        .validate(isPassword, { abortEarly: false });
      setShowValidation(false);
    } catch (validationError) {
      setShowValidation(true);
    }
  };

  return (
    <>
      {isTokenActive ? (
        <>
          {!isSend ? (
            <LoginContent heading={formatMessage({ id: 'create_password' })}>
              <div className="suspend-user-modal__descript">{formatMessage({ id: 'admin_activation' })}</div>
              <Formik
                validationSchema={validationSchema}
                initialValues={{ password: '', confirmPassword: '' }}
                validateOnBlur={false}
                enableReinitialize
                onSubmit={async (values) => handleActivateUser(values)}>
                {({ handleSubmit, errors, values }) => (
                  <form onSubmit={handleSubmit}>
                    <Field
                      label={formatMessage({ id: 'password' })}
                      name="password"
                      placeholder=""
                      required
                      component={Input}
                      treshold={0}
                      type="password"
                      maxLength={32}
                      inputOnFocus={() => setShowValidation(true)}
                      onChange={(e) => isSetPassword(e)}
                      onBlur={() => {
                        handleBlur();
                      }}
                      errors={errors}
                    />
                    <PasswordStrengthChecker isHidden={!showValidation} password={values.password} />
                    <PasswordValidation
                      setIsValid={(value: boolean) => setIsPasswordValid(value)}
                      className={` ${showValidation ? 'validation-item__wrapper--show' : ''}`}
                      password={values.password}
                    />
                    <Field
                      label={formatMessage({ id: 'repeat_password' })}
                      name="confirmPassword"
                      placeholder=""
                      required
                      component={Input}
                      treshold={0}
                      type="password"
                      maxLength={32}
                      errors={errors}
                    />
                    <div className="login-content__button">
                      <Button label={formatMessage({ id: 'save' })} type="submit" />
                    </div>
                  </form>
                )}
              </Formik>
            </LoginContent>
          ) : (
            <LoginContent
              heading={formatMessage({ id: 'password_activated' })}
              activate
              descript={formatMessage({ id: 'password_activated_description' })}>
              <div className="login-content__button login-content__button--activate">
                <Button label={formatMessage({ id: 'login' })} type="submit" onClick={() => navigate({ pathname: ROUTES.LOGIN })} />
              </div>
            </LoginContent>
          )}
        </>
      ) : (
        <LoginContent
          acitaveLinkExpired
          heading={formatMessage({ id: 'something_went_wrong' })}
          descript={formatMessage({ id: 'link_expired_contact_admin' })}></LoginContent>
      )}
    </>
  );
};

export default ActivateUserPage;
