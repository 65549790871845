enum VALIDATION_MESSAGES {
  REQUIRED = 'To pole jest wymagane',
  EMAIL = 'Podaj pełny adres email',
  EMAIL_TAKEN = 'Podany adres email jest już zajęty',
  PASSWORD_LENGTH = 'Hasło musi zawierać co najmniej 8 znaków',
  PASSWORD_MATCH = 'Hasła muszą być takie same',
  NIP = 'Podaj poprawny numer NIP',
  PHONE = 'Podaj poprawny numer telefonu',
  PHONE_PREFIX = 'Podaj poprawny numer kierunkowy',
  POST_CODE = 'Podaj poprawny kod pocztowy',
  MIN_3 = 'Podaj co najmniej 3 znaki',
  MIN_5 = 'Podaj co najmniej 5 znaków',
  ONLY_LETTERS = 'Pole może zawierać tylko litery',
  TOO_LONG = 'Podana wartość jest za długa',
  NUMBER = 'Podaj liczbę',
  QUALITY_REQUIED = 'Wybierz pakiet, aby przejść dalej',
  MIN_0 = 'Podaj wartość większą niż 0',
  DIGITS = 'Podaj poprawną liczbę, zawierającą maksymalnie 2 cyfry po kropce',
  MAX_999 = 'Podaj wartość mniejszą niż 1000',
  PASSWORD_MISMATCH = 'Hasła muszą być takie same',
  CANNOT_START_WITH_SPACE = 'Hasło nie może zaczynać się spacją',
  CANNOT_SET_OLD_PASSWORD = 'Nowe hasło nie może być takie samo jak aktualne',
  MIN_LENGTH_3 = 'Pole musi mieć minimum 3 znaki',
  CANNOT_START_AND_END_WITH_SPACE = 'Pole nie może zaczynać się ani kończyć spacją',
  MAX_LENGTH_30 = 'Pole może mieć maksymalnie 30 znaków',
  MAX_LENGTH_200 = 'Pole może mieć maksymalnie 200 znaków',
  INVALID_LINK = 'Wprowadzony link jest niepoprawny',

  REQUIRED_EN = 'This field is required',
  CANNOT_START_AND_END_WITH_SPACE_EN = 'Field cannot begin or end with a space',
  MIN_LENGTH_3_EN = 'Field must have a minimum of 3 characters',
  MAX_LENGTH_200_EN = 'Field must have a maximum  200 characters',
}

export default VALIDATION_MESSAGES;
