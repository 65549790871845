import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import { FieldProps } from 'formik';

interface CheckboxProps {
  name?: string;
  label?: string;
  className?: string;
  id?: string;

  //formik
  field?: any;
  errors?: any;
  form?: any;
  touched?: any;
  onChange?: (value) => void;
  handleOnChange?: any;
  isOnBlurAction?: boolean;
  onFocus?: any;
  children?: any;
}

function Checkbox({ field, errors = {}, form, label, className = '', children }: CheckboxProps & FieldProps) {
  const handleOnClick = () => {
    form.setFieldValue(field?.name, !field.value);
  };

  return (
    <div className={`checkbox ${className}`}>
      <label
        className={`checkbox__option checkbox__label-wrapper ${field.value !== true ? 'checkbox__option--not-selected' : 'checkbox__option--selected'}  ${
          form.touched[field.name] && errors[field.name] ? 'checkbox__option--error' : ''
        }`}>
        <input type="checkbox" value={field.value} onChange={() => handleOnClick()} />
        <span className={`checkbox__dummy-input ${field.value ? 'checkbox__dummy-input--checked' : ''}`}>
          <SVG className={`checkbox__svg ${field.value ? 'checkbox__svg--checked' : ''}`} type={SVG_TYPE.CHECKBOX_CHECKED} />
        </span>
        <span className="checkbox__title-wrapper checkbox__label">{children ? children : label}</span>
      </label>
      {errors && form.touched && <div className="input__error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>}
    </div>
  );
}

export default Checkbox;
