import * as StudyModule from '../../../Modules/StudyModule';
import { Study } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import { translatedMessage } from 'Shared/utils';

const init = () => ({
  type: Study.ADD_STUDY_FILE_INIT,
});

const success = (files) => ({
  type: Study.ADD_STUDY_FILE_SUCCESS,
  payload: {
    data: files,
  },
});

const failure = () => ({
  type: Study.ADD_STUDY_FILE_FAILURE,
});

const addStudyFiles = (dataFile, data) => async (dispatch) => {
  dispatch(init());
  const id = toast.loading(translatedMessage('toast.pending_add_study_file'));
  const file = new FormData();
  file.append('file', dataFile[0]);
  try {
    const response = await StudyModule.Connector.addStudyFiles(file, data);
    toast.update(id, {
      render: translatedMessage('toast.add_study_file'),
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    dispatch(success(response.data));
    return response.data;
  } catch (err: any) {
    dispatch(failure());
    toast.update(id, {
      render: translatedMessage('toast.add_study_file_error'),
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
    });
    return false;
  }
};

export default addStudyFiles;
