import React, { useState, useEffect } from 'react';
import 'rc-slider/assets/index.css';
import { default as RcSlider } from 'rc-slider';
import { Button, SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import { useIntl } from 'react-intl';

type CustomParameterSliderType = {
  index: number;
  localStorageForm: any;
  values: any;
  itemScale: any;
  setFieldValue: any;
};

const CustomParameterSlider = ({ values, index, localStorageForm, itemScale, setFieldValue }: CustomParameterSliderType) => {
  const isMobile = window.innerWidth < 1024;
  const [scaleLevels, setScaleLevels] = useState(values.parameters.map((item) => item.scaleLevel || 5));
  const [activeMark, setActiveMark] = useState(null);
  const arrayOfStrings = [];
  const [inputValues, setInputValues] = useState(arrayOfStrings);
  const [marksHeight, setMarksHeight] = useState(null);
  const { formatMessage } = useIntl();

  useEffect(() => {
    const scaleLevelsIndex = scaleLevels[index];
    const newArrayOfStrings = [];
    for (let i = 0; i < scaleLevelsIndex; i++) {
      const titleFromLocalStorage = (values.parameters && values.parameters[index]?.titles[i]) || '';
      newArrayOfStrings.push(titleFromLocalStorage);
    }
    setInputValues(newArrayOfStrings);
  }, [values.parameters]);

  useEffect(() => {
    const scaleLevelsIndex = scaleLevels[index];
    for (let i = 0; i < scaleLevelsIndex; i++) {
      const titleFromLocalStorage = (localStorageForm.parameters && localStorageForm.parameters[index]?.titles[i]) || '';
      arrayOfStrings.push(titleFromLocalStorage);
    }
  }, []);

  useEffect(() => {
    const slider = document.getElementsByClassName(`slider-param-${index}`);
    const sliderMarks = slider[0].getElementsByClassName('rc-slider-mark');
    const marks = sliderMarks[0].getElementsByClassName('rc-slider-mark-text');
    let maxHeight = 0;
    for (let i = 0; i < marks.length; i++) {
      const element = marks[i];
      const elementHeight = element.clientHeight;
      if (elementHeight > maxHeight) {
        maxHeight = elementHeight;
      }
    }
    setMarksHeight(maxHeight);
  }, [activeMark]);

  useEffect(() => {
    setScaleLevels(values.parameters.map((item) => item.scaleLevel || 5));
  }, [values.parameters]);

  const handleMarkClick = (mark) => {
    setActiveMark(mark);
    setTimeout(() => {
      const input = document.getElementById(`input-mark-${mark}`);
      if (input) {
        input.focus();
      }
    }, 0);
  };

  const handleInputBlur = () => {
    setActiveMark(null);
    const updatedParameterScreen = [...values.parameters];
    updatedParameterScreen[index] = {
      ...updatedParameterScreen[index],
      titles: [...inputValues],
      scaleLevel: itemScale,
    };
    setFieldValue('parameters', updatedParameterScreen);
    sessionStorage.setItem('formTest', JSON.stringify({ ...localStorageForm, parameters: updatedParameterScreen }));
  };

  const handleInputChange = (mark, value) => {
    const newInputValues = [...inputValues];
    newInputValues[mark - 1] = value;
    setInputValues(newInputValues);
  };

  const generateMobileForm = () => {
    const numMarks = scaleLevels[index];
    const formElements = [];
    for (let i = 1; i <= numMarks; i++) {
      formElements.push(
        <div className="test-form-slider-mark">
          <div className="test-form-slider-mark__form-input-label">
            {i} {formatMessage({ id: 'step' })}
          </div>
          <div className="test-form-slider-mark__form-input">
            <input
              id={`input-mark-${i}`}
              value={inputValues[i - 1] || ''}
              onChange={(e) => handleInputChange(i, e.target.value)}
              onBlur={() => handleInputBlur()}
              onKeyDown={(e) => console.log(e.code)}
            />
          </div>
        </div>,
      );
    }

    return formElements;
  };

  const generateMarks = () => {
    const numMarks = scaleLevels[index];
    const marks = {};

    const handleInputKeyDown = (e) => {
      if (e.code === 'Enter') {
        e.preventDefault();
        setActiveMark(null);
      }
    };

    for (let i = 1; i <= numMarks; i++) {
      marks[i] = {
        label: (
          <div
            className={`test-form-slider-mark ${
              numMarks > 5 || (numMarks <= 5 && activeMark == 0) ? 'test-form-slider-mark--small' : 'test-form-slider-mark--big'
            } ${(numMarks > 5 && inputValues[i - 1]) || activeMark === i ? 'test-form-slider-mark--big' : ''}`}>
            <div className="test-form-slider-mark__number">{i}</div>
            {!isMobile && (
              <>
                {activeMark === i ? (
                  <div className="test-form-slider-mark__input">
                    <input
                      id={`input-mark-${i}`}
                      value={inputValues[i - 1] || ''}
                      onChange={(e) => handleInputChange(i, e.target.value)}
                      onBlur={() => handleInputBlur()}
                      onKeyDown={(e) => handleInputKeyDown(e)}
                      maxLength={60}
                    />
                  </div>
                ) : (
                  <>
                    {inputValues[i - 1] ? (
                      <div className="test-form-slider-mark__input-label" onClick={() => handleMarkClick(i)}>
                        <div className="text">{inputValues[i - 1]}</div>
                        <SVG type={SVG_TYPE.EDIT} />
                      </div>
                    ) : (
                      <Button
                        className={`${numMarks > 5 ? 'btn-small' : ''}`}
                        label={`${numMarks > 5 ? '+' : '+ ' + formatMessage({ id: 'signature' })}`}
                        stroke
                        black
                        onClick={() => handleMarkClick(i)}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        ),
      };
    }

    return marks;
  };

  const marks = generateMarks();

  return (
    <>
      <div style={{ height: `${marksHeight + 15}px` }}>
        <RcSlider
          style={{ height: `${marksHeight + 15}px` }}
          className={`slider-param slider-param-${index}`}
          marks={marks}
          dots
          max={scaleLevels[index]}
          min={1}
        />
        {isMobile && <div className="test-form-slider-mark__form-inputs">{generateMobileForm()}</div>}
      </div>
    </>
  );
};

export default CustomParameterSlider;
