import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { flattenMessages, Locale, Router } from 'Shared/utils';
import { Provider } from 'react-redux';
import { store } from 'Store/Store';
import { BrowserRouter } from 'react-router-dom';

function App() {
  const translation = Locale();
  const [, setIsReady] = useState(false);
  const translations = ['pl', 'en'];
  const setTranslations = () => {
    translations.forEach((translationItem) => {
      if (translation.toLocaleLowerCase() === translationItem) {
        const script = document.createElement('script');
        script.src = `/translations/${translation.toLowerCase()}.js`;
        script.onload = () => {
          localStorage.setItem('translation', translation.toUpperCase());
          setIsReady(true);
        };
        document.head.appendChild(script);
      } else {
        const script = document.createElement('script');
        script.src = `/translations/${translationItem.toLowerCase()}.js`;
        document.head.appendChild(script);
      }
    });
  };

  useEffect(() => {
    setTranslations();
  }, [translation]);

  return (
    <IntlProvider
      messages={flattenMessages((window as any)?.translations?.[translation.toLowerCase()].global)}
      locale={translation.toLowerCase()}
      key={translation.toLowerCase()}
      defaultLocale={translation.toLowerCase()}>
      <Provider store={store}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </Provider>
    </IntlProvider>
  );
}
export default App;
