import * as CustomTranslationsModule from 'Modules/CustomTranslationsModule';
import { CustomTranslations } from '../EnumTypes/ActionEnumTypes';

const success = (data) => ({
  type: CustomTranslations.GET_CUSTOM_TRANSLATIONS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: CustomTranslations.GET_CUSTOM_TRANSLATIONS_FAILURE,
});

const getCustomTranslations = (uuid) => async (dispatch) => {
  try {
    const { data } = await CustomTranslationsModule.Connector.getCustomTranslation(uuid);
    dispatch(success(data.translations));
  } catch (err: any) {
    dispatch(failure());
  }
};

export default getCustomTranslations;
