import * as AuthModule from 'Modules/AuthModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import { translatedMessage } from 'Shared/utils';

export const success = () => ({
  type: Auth.RECOVER_PASSWORD_SUCCESS,
});

const failure = () => ({
  type: Auth.RECOVER_PASSWORD_FAILURE,
});

const recoverPassword = (password, token) => async (dispatch) => {
  const id = toast.loading(translatedMessage('toast.pending_password_change'));
  try {
    await AuthModule.Connector.setPassword(password, token);
    dispatch(success());
    toast.update(id, {
      render: translatedMessage('toast.password_change'),
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    return true;
  } catch (err) {
    dispatch(failure());
    toast.update(id, {
      render: translatedMessage('toast.password_change_error'),
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
    });
    return false;
  }
};

export default recoverPassword;
