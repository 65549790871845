import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

const getStudiesStore = (state: RootState) => state.studyStore;

const getStudies = createSelector(getStudiesStore, (state) => state.studies);
const getStudyFiles = createSelector(getStudiesStore, (state) => state.studyFiles);
const getStudyByLink = createSelector(getStudiesStore, (state) => state.studyByLink);
const getStudyAnswersList = createSelector(getStudiesStore, (state) => state.studyAnswersList);
const getStudyForEdit = createSelector(getStudiesStore, (state) => state.editStudy);

export { getStudies, getStudyFiles, getStudyByLink, getStudyAnswersList, getStudyForEdit };
