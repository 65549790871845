type BadgeType = {
  label: string;
  status: string;
};
const Badge = ({ label, status }: BadgeType) => {
  return (
    <div className={`badge ${status ? `badge--${status}` : ''}`}>
      <p className="badge-label">{label}</p>
    </div>
  );
};

export default Badge;
