import { useState } from 'react';
import { Button, SVG } from 'Shared/components';
import { AdministratorsList, AddAdministratorModal } from './components';
import { MainType } from './Container';
import { SVG_TYPE, USER_ROLES } from 'Shared/enums';
import { useIntl } from 'react-intl';

const modalType = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
};

const AdministratorsPage = (props: MainType) => {
  const isMobile = window.innerWidth < 1024;
  const [modalManage, setModalManage] = useState('');
  const isAddAdministratorModalOpened = modalManage === modalType.ADD;
  const { actions, users, authorizedUser } = props;
  const { getUsers, addUser, editUser, suspendUser, resendActivateLink } = actions;
  const { formatMessage } = useIntl();
  const [role, setRole] = useState(USER_ROLES.ADMIN);

  const handleCloseModal = () => {
    setModalManage('');
  };

  return (
    <div className="dashboard-content">
      <AddAdministratorModal
        getUsers={getUsers}
        addUser={addUser}
        isModalOpen={isAddAdministratorModalOpened}
        closeModal={() => handleCloseModal()}
      />
      <div className="dashboard-header">
        <div className="dashboard-heading">{formatMessage({ id: 'users' })}</div>
        {role === USER_ROLES.ADMIN ? (
          isMobile ? (
            <div className="administrator-page__add-user" onClick={() => setModalManage(modalType.ADD)}>
              <SVG type={SVG_TYPE.PLUS} />
            </div>
          ) : (
            <Button label={`${formatMessage({ id: 'add' })} +`} onClick={() => setModalManage(modalType.ADD)} />
          )
        ) : null}
      </div>
      <AdministratorsList
        activeUserUuid={authorizedUser?.uuid}
        getUsers={getUsers}
        resendActivateLink={resendActivateLink}
        suspendUser={suspendUser}
        editUser={editUser}
        users={users}
        setRole={setRole}
        role={role}
      />
    </div>
  );
};

export default AdministratorsPage;
