import * as Types from 'Shared/models/StudyModel';
import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';
import { AuthAction } from '../Actions/Models/AuthActionModel';

export type State = Types.StudyStore;

export const initialState: State = {
  studies: null,
  studyFiles: [],
  studyByLink: null,
  studyAnswersList: {},
  editStudy: null,
};

export function studyReducer(state: State = initialState, action: AuthAction) {
  switch (action.type) {
    case ActionTypes.Study.GET_STUDIES_SUCCESS: {
      return {
        ...state,
        studies: action.payload.data,
      };
    }
    case ActionTypes.Study.GET_STUDY_FILES_SUCCESS: {
      return {
        ...state,
        studyFiles: action.payload.data,
      };
    }
    case ActionTypes.Study.ADD_STUDY_FILE_SUCCESS: {
      return {
        ...state,
        studyFiles: [...state.studyFiles,action.payload.data],
      };
    }
    case ActionTypes.Study.DELETE_STUDY_FILE_SUCCESS: {
      return {
        ...state,
        // studyFiles: state.studyFiles.filter((file) => file.uuid !== action.payload.uuid),
      };
    }
    case ActionTypes.Study.GET_STUDY_BY_LINK_SUCCESS: {
      return {
        ...state,
        studyByLink: action.payload.data,
      };
    }
    case ActionTypes.Study.GET_STUDY_ANSWER_LIST_SUCCESS: {
      return {
        ...state,
        studyAnswersList: action.payload.data,
      };
    }
    case ActionTypes.Study.GET_STUDY_FOR_EDIT_INIT: {
      return {
        ...state,
        editStudy: null,
      };
    }
    case ActionTypes.Study.GET_STUDY_FOR_EDIT_SUCCESS: {
      return {
        ...state,
        editStudy: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
}
